/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import $api from '../api'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const maxAge = 30 * 60 * 1000; //fifteen minutes

const authVuexPersist = new VuexPersist({
  key: 'authStore',
  storage: window.localStorage,
  modules: ['auth'],
  filter: (mutation) =>
    mutation.type == 'AUTH_SUCCESS' ||
    mutation.type == 'AUTH_LOGOUT'
});

const appVuexPersist = new VuexPersist({
  key: 'appStore',
  storage: window.localStorage,
  modules: ['app'],
  filter: (mutation) =>
    mutation.type == 'setCountries' ||
    mutation.type == 'setDistricts' ||
    mutation.type == 'setCities' ||
    mutation.type == 'setAreas'
});

const clientVuexPersist = new VuexPersist({
  key: 'clientStore',
  storage: window.localStorage,
  modules: ['client'],
  filter: (mutation) =>
    mutation.type == 'setClient' ||
    mutation.type == 'setSelectedClient' ||
    mutation.type == 'setClients'
});

const authStore = {
  state: {
    me: {},
    token: "",
    userId: "",
    userName: ""
  },
  mutations: {
    AUTH_SUCCESS(state, data) {
      state.token = data.jwt,
      state.userId = data.user.id,
      state.userName = data.user.name
    },
    AUTH_LOGOUT: state => {
      state.token = "";
    },
    setMe(state, me) {
      state.me = me;
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    getMe: (state) => {
      return state.me;
    },
  },
  actions: {
    AUTH_REQUEST: ({ commit }, credentials) => {
      return new Promise((resolve, reject) => {
        $api.makeLogin(credentials).then((response) => {
          if (!response.data.data.login.jwt){
            reject("Utilizador sem permissão.");
          }
          commit("AUTH_SUCCESS", response.data.data.login);
          resolve(response.data.data.login.jwt);
        }).catch(err => {
          reject("Dados não válidos");
        });
      });
    },
    AUTH_VALIDATE: ({ state, getters, commit }) => {
      return new Promise((resolve, reject) => {
        $api.validateJWT().then(async (response) => {
          let me = response.data;
          await $api.getEventsByUser(state.userId).then((response) => {
            me.events = response.data.data.events;
          });
          commit("setMe", me);
          resolve(true);
        }).catch(err => {
          commit("AUTH_LOGOUT");
          resolve(false);
        });
      });
    },
    AUTH_LOGOUT: ({ commit }) => {
      return new Promise(resolve => {
        commit("AUTH_LOGOUT");
        resolve();
      });
    }
  },
};

const appStore = {
  state: {
    countries: [],
    districts: [],
    cities: [],
    areas: [],
    providers: [],
    users: [],
    consumptions: [],
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setDistricts(state, districts) {
      state.districts = districts;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setAreas(state, areas) {
      state.areas = areas;
    },
    setProviders(state, providers) {
      state.providers = providers;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setConsumption(state, consumption) {
      state.consumptions.push(consumption);
    }
  },
  getters: {
    getCountries: (state) => {
      return state.countries;
    },
    getDistricts: (state) => {
      return state.districts;
    },
    getDistrictsByCountry: (state) => (country) => {
      if (country) {
        return state.districts.filter((district) => {
          return district.country.id == country;
        });
      } else {
        return [];
      }
    },
    getCities: (state) => {
      return state.cities;
    },
    getCitiesByDistrict : (state) => (district) => {
      if (district) {
        return state.cities.filter((city) => {
          return city.district.id == district;
        });
      } else {
        return [];
      }
    },
    getAreas: (state) => {
      return state.areas;
    },
    getAreasByCity: (state) => (city) => {
      if (city) {
        return state.areas.filter((area) => {
          return area.city.id == city;
        });
      } else {
        return [];
      }
    },
    getProviders: (state) => {
      return state.providers; //to consider return by id
    },
    getUsers: (state) => {
      return state.users; //to consider return by id
    },
    getConsumptions: (state) => (dropId) => {
      return state.consumptions.filter(consumption => {
        return dropId.some(drop => {
          return drop == consumption.dropId;
        });
      });
    },
  },
  actions: {
    fetchConsumption: ({commit, getters}, payload) => {
      return new Promise((resolve, reject) => {
        if (!getters.getConsumptions(payload.dropIds).length ||
            (payload.force && payload.force==true)) {
          if (!payload.startDate) payload.startDate = null;
          if (!payload.endDate) payload.endDate = null;
          console.log("Getting consumptions from remote");
          $api.getConsumptions(payload.dropIds, payload.startDate, payload.endDate).then((response) => {
            if (!response.data.data.consumptionsConnection) {
              reject();
            }
            let drops = response.data.data.consumptionsConnection.groupBy.drop;
            drops.forEach(drop => {
              let consumptions = drop.connection.values;
              consumptions.forEach(consumption => {
                let {drop, ...data} = consumption;
                commit("setConsumption", {dropId: drop.id, data: data});
              });
            });
            resolve(getters.getConsumptions(payload.dropIds));
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting Consumption from store");
          resolve(getters.getConsumptions(payload.dropIds));
        }
      });
    },
    fetchProviders: ({ commit, getters }) => {
      return new Promise((resolve, reject) => {
        let providers = getters.getProviders;
        if (!providers.length) {
          console.log("Getting providers from remote");
          $api.getProviders().then((response) => {
            if (!response.data.data.providers){
              reject();
            }
            commit("setProviders", response.data.data.providers);
            resolve(getters.getProviders);
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting providers from local");
          resolve(getters.getProviders);
        }
      })
    },
    fetchUsers: ({ commit, getters }) => {
      return new Promise((resolve, reject) => {
        let users = getters.getUsers;
        if (!users.length) {
          console.log("Getting users from remote");
          $api.getUsers().then((response) => {
            if (!response.data.data.users){
              reject();
            }
            commit("setUsers", response.data.data.users);
            resolve(getters.getUsers);
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting users from local");
          resolve(getters.getUsers);
        }
      })
    },
    fetchAddresses: ({ commit, getters }, payload={}) => {
      let forceUpdate = payload.forceUpdate ?? false;
      return new Promise((resolve, reject) => {
        let promises = [];

        let countries = getters.getCountries;
        if (!countries.length || forceUpdate) {
          console.log("Getting countries from remote");
          promises.push($api.getCountries().then((response) => {
            if (!response.data.data.countries){
              reject();
            }
            commit("setCountries", response.data.data.countries);
            //resolve(getters.getCountries);
          }).catch(err => {
            reject(err);
          }));
        } else {
          console.log("Getting countries from local");
          //resolve(getters.getCountries);
        }

        let districts = getters.getDistricts;
        if (!districts.length || forceUpdate) {
          console.log("Getting districts from remote");
          promises.push($api.getDistricts().then((response) => {
            if (!response.data.data.districts){
              reject();
            }
            commit("setDistricts", response.data.data.districts);
            //resolve(getters.getDistricts);
          }).catch(err => {
            reject(err);
          }));
        } else {
          console.log("Getting districts from local");
          //resolve(getters.getDistricts);
        }

        let cities = getters.getCities;
        if (!cities.length || forceUpdate) {
          console.log("Getting cities from remote");
          promises.push($api.getCities().then((response) => {
            if (!response.data.data.cities){
              reject();
            }
            commit("setCities", response.data.data.cities);
            //resolve(getters.getCities);
          }).catch(err => {
            reject(err);
          }));
        } else {
          console.log("Getting cities from local");
          //resolve(getters.getCities);
        }

        let areas = getters.getAreas;
        if (!areas.length || forceUpdate) {
          console.log("Getting areas from remote");
          promises.push($api.getAreas().then((response) => {
            if (!response.data.data.areas){
              reject();
            }
            commit("setAreas", response.data.data.areas);
            //resolve(getters.getAreas);
          }).catch(err => {
            reject(err);
          }));
        } else {
          console.log("Getting areas from local");
          //resolve(getters.getAreas);
        }

        Promise.all(promises).then((values) => {
          resolve({
            countries: getters.getCountries,
            districts: getters.getDistricts,
            cities: getters.getCities,
            areas: getters.getAreas,
          });
        });
      });
    },
  },
};

const clientStore = {
  state: {
    selectedClient: 0,
    clients: {data: [], map: {}, lastUpdate: 0},
  },
  mutations: {
    setSelectedClient(state, client) {
      state.selectedClient = client;
    },
    setClients(state, clients) {
      state.clients.data = clients;

      let clientesMap = clients.reduce(function (acc, obj) {
        acc[obj.id] = obj;
        return acc;
      }, {});

      state.clients.map = clientesMap;
      state.clients.lastUpdate = new Date().getTime();
    },
    clearClientsLastUpdate(state) {
      state.clients.lastUpdate = 0;
    },
    setClient(state, data) {
      state.clients.map[data.id] = data;
      // let clientIndex = state.clients.data.findIndex(client => client.id == data.id);
      // if (clientIndex < 0) {
      //   state.clients.data.push(data);
      // } else {
      //   state.clients.data[clientIndex] = data;
      // }
    },
    removeClient(state, data) {
      delete state.clients.map[data.id];
      // let clientIndex = state.clients.data.findIndex(client => client.id == data.id);
      // if (clientIndex >= 0) {
      //   state.clients.data.splice(clientIndex, 1);
      // }
    },
    setInfo(state, payload) {
      let client = state.clients.map[payload.id];
      state.clients.map[payload.id] = {...client, ...payload.info};
      // let clientIndex = state.clients.data.findIndex(client => client.id == payload.id);
      // if (clientIndex >= 0) {
      //   state.clients.data[clientIndex] = {...state.clients.data[clientIndex], ...payload.info};
      // }
    },
    setContracts(state, payload) {
      state.clients.map[payload.id].contracts = payload.contracts;
      // state.clients.data.find(client => client.id == payload.id)
      //   .contracts = payload.contracts;
    },
    setContacts(state, payload) {
      state.clients.map[payload.id].contacts = payload.contacts;
      // state.clients.data.find(client => client.id == payload.id)
      //   .contacts = payload.contacts;
    },
    setEvents(state, payload) {
      if (payload && payload.id) {
        state.clients.map[payload.id].events = payload.events;
        // state.clients.data.find(client => client.id == payload.id)
        //   .events = payload.events;
      }
    },
  },
  getters: {
    getSelectedClient: (state) => {
      return state.selectedClient;
    },
    getClientsLastUpdate: (state) => {
      return state.clients.lastUpdate;
    },
    getClients: (state) => (search) => {
      let result = state.clients.data;
      if (!search) {
        return result;
      }

      if (search.nifDesignationObs) {
        /******* FOR 'OR' COMPARE ***********/
        //let searchNormalized = search.nifDesignationObs.replace(/\s+/g, '|').normalize('NFD');//.replace(/([\u007c]|[\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        //let regex = searchNormalized;
        /************************************/
        /******* FOR 'AND' COMPARE ***********/
        let searchNormalized = search.nifDesignationObs.trim().replace(/\s+/g, ')(?=.*').normalize('NFD');//.replace(/([\u007c]|[\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        let regex = '(?=.*'+searchNormalized+')';
        /************************************/
        result = result.filter((client) => {
          const designationNormalized = client.designation.trim().replace(/\s+/g, ' ').normalize('NFD');//.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          const obsNormalized = client.obs?.trim().replace(/\s+/g, ' ').normalize('NFD');
          const tradeNameNormalized = client.tradeName?.trim().replace(/\s+/g, ' ').normalize('NFD');

          const normalized = (designationNormalized +" "+obsNormalized+" "+tradeNameNormalized+" "+client.taxNumber).trim();
          return (new RegExp(regex, 'gi').test(normalized));
        });
      }
      if (search.activity) {
        /******* FOR 'OR' COMPARE ***********/
        let searchNormalized = search.activity.replace(/\s+/g, '|').normalize('NFD');//.replace(/([\u007c]|[\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        let regex = searchNormalized;
        result = result.filter(client => {
          const activityNormalized = client.activity?.trim().replace(/\s+/g, ' ').normalize('NFD');
          return (new RegExp(regex, 'gi').test(activityNormalized));
        });
      }
      if (search.country) {
        result = result.filter(client => client.fullAddress.country && client.fullAddress.country.id == search.country);
      }
      if (search.district) {
        result = result.filter(client => client.fullAddress.district && client.fullAddress.district.id == search.district);
      }
      if (search.city) {
        result = result.filter(client => client.fullAddress.city && client.fullAddress.city.id == search.city);
      }
      if (search.area) {
        result = result.filter(client => client.fullAddress.area && client.fullAddress.area.id == search.area);
      }

      return result;
    },
    getClient: (state) => (id) => {
      if (!id) {
        return {};
      } else {
        return state.clients.map[id] ?? {};
        // return state.clients.data.find((client) => client.id == id);
      }
    },
    taxNumberExists: (state) => (nif) => {
      if (!nif) {
        return false;
      } else {
        return !!state.clients.data.find((client) => client.taxNumber == nif);
      }
    },
  },
  actions: {
    fetchClients: ({ commit, getters }, search) => {
      return new Promise((resolve, reject) => {
        if ((new Date().getTime() - getters.getClientsLastUpdate) > maxAge) {
          console.log("Getting clients from remote");
          $api.getClients().then((response) => {
            if (!response.data.data.clients){
              reject();
            }
            commit("setClients", response.data.data.clients);
            resolve(getters.getClients(search));
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting clients from store");
          resolve(getters.getClients(search));
        }
      });
    },
    fetchInfo: ({commit, getters}, clientId) => {
      return new Promise((resolve, reject) => {
        if (!getters.getClient(clientId).phone1) {
          console.log("Getting info from remote");
          $api.getInfo(clientId).then((response) => {
            if (!response.data.data.clients.length){
             reject();
            }
            commit("setInfo", {id: clientId, info: response.data.data.clients[0]});
            resolve(getters.getClient(clientId));
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting info from store");
          resolve(getters.getClient(clientId));
        }
      });
    },
    fetchContracts: ({ commit, getters}, clientId) => {
      return new Promise((resolve, reject) => {
        if (!getters.getClient(clientId).contracts) {
          console.log("Getting contracts from remote");
          $api.getContracts(clientId).then((response) => {
            if (!response.data.data.contracts){
             reject();
            }
            commit("setContracts", {id: clientId, contracts: response.data.data.contracts});
            resolve(getters.getClient(clientId).contracts);
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting contracts from store");
          resolve(getters.getClient(clientId).contracts);
        }
      });
    },
    fetchContacts: ({ commit, getters}, {clientId, forceUpdate=false}) => {
      return new Promise((resolve, reject) => {
        if (!getters.getClient(clientId).contacts || forceUpdate) {
          console.log("Getting contacts from remote");
          $api.getContacts(clientId).then((response) => {
            if (!response.data.data.contacts){
             reject();
            }
            commit("setContacts", {id: clientId, contacts: response.data.data.contacts});
            resolve(getters.getClient(clientId).contacts);
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Getting contacts from store");
          resolve(getters.getClient(clientId).contacts);
        }
      });
    },
    fetchEvents: ({ commit, getters}, payload={}) => {
      let forceUpdate = payload.forceUpdate ?? false;
      let clientId = payload.clientId ?? false;
      let startDate = payload.startDate ?? false;
      let endDate = payload.endDate ?? false;
      //let {clientId=null, forceUpdate=false} = payload;
      return new Promise((resolve, reject) => {
        if(clientId) {
          if ((!getters.getClient(clientId)?.events?.length) || forceUpdate) {
            console.log("Getting events for client from remote");
            $api.getEventsByClient(clientId).then((response) => {
              if (!response.data.data.events){
                reject();
              }
              commit("setEvents", {id: clientId, events: response.data.data.events});
              resolve(getters.getClient(clientId).events);
            }).catch(err => {
              reject(err);
            });
          } else {
            console.log("Getting events for client from store");
            resolve(getters.getClient(payload.clientId).events);
          }
        } else if(startDate && endDate) {
          console.log("Getting events from remote");
          $api.getEventsBetweenDates(startDate, endDate).then((response) => {
            if (!response.data.data.events){
              reject();
            }
            console.log(response);
            //commit("setEvents", {id: payload.clientId, events: response.data.data.events});
            //resolve(getters.getClient(payload.clientId).events);
            resolve(response);
          }).catch(err => {
            reject(err);
          });
        } else {
          console.log("Not getting events");
          reject();
        }
      });
    },
  }
}

export default new Vuex.Store({
  plugins: [authVuexPersist.plugin, appVuexPersist.plugin, clientVuexPersist.plugin],
  modules: {
    auth: authStore,
    app: appStore,
    client: clientStore,
  }
})
