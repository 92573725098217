/* eslint-disable */
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

export default {
  methods: {
    routeTo(destination) {
      this.$router.push(destination).catch(()=>{});
    },
    getSafe(fn) {
      //alternative to "optional chaining" inside template
      try { return fn(); }
      catch (e) {}
    },
    isBeforeToday(date) {
      if (!date) return null;
      return moment(date).isBefore();
    },
    dateToYYYYMMDD(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime()/1000).format('YYYY-MM-DD');
    },
    dateToYYYYMMDDHHMM(date) {
      if (!date) return null;
      return moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD HH:mm');
    },
    diffDatesDays(start, end) {
      start = moment(start);
      end = moment(end);
      return moment.duration(end.diff(start, 'days'), 'days').asDays();
    },
    diffDatesMonths(start, end) {
      start = moment(start);
      end = moment(end);
      return moment.duration(end.diff(start)).asMonths();
    },
    subtractMonths(months, day = new Date()){
      return moment(day).subtract(months, 'months');
    },
    getRandomColor(min=50, max=200) {
      let green = (Math.floor(Math.random() * (max - min) + min));
      let red = (Math.floor(Math.random() * (max - min) + min));
      let blue = (Math.floor(Math.random() * (max - min) + min));
      let color = '#' + green.toString(16) + blue.toString(16) + red.toString(16);
      return color
    },
  }
};
