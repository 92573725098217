/* eslint-disable */
import Vue from 'vue';

export default {
  methods: {
    buildDatasets(val) {
      let dataset = {
        backgroundColor: "#0d7507",
        data: []
      };
      let tempData = [];
      let tempColor = [];
      val.forEach(element => {
        tempData.push(element.data[11]);
        tempColor.push(element.color);
        dataset = {
          backgroundColor: tempColor,
          data: tempData
        }
      });
      return dataset
    },
    buildLabels(val) {
      let temp = [];
      val.forEach(element => {
        temp.push(element.name);
      })
      return temp;
    },
    buildBarDatasets(val){
      let barData = [];
      let dataset = {};
      val.forEach(element => {
        dataset = {
          label: element.name,
          backgroundColor: element.color,
          data: element.data
        }
       barData.push(dataset);
      })
      return barData
    },
  }
}