<template>
  <v-dialog
    v-model="show"
    persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5"> Novo Contrato </span>
      </v-card-title>
        <v-stepper
          non-linear
          v-model="stepperIndex">
          <v-stepper-header>
            <v-stepper-step step="1"
              editable>
              Dados Básicos
            </v-stepper-step>
            <v-divider/>
            <v-stepper-step step="2"
              editable>
              {{ stepperText }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="contractForm">
                <v-card-text>
                  <v-container>
                    <v-row dense align="center" justify="center">
                      <v-col :cols="4">
                        <v-text-field
                          label="Nº Contrato"
                          v-model="contract.contractNumber">
                        </v-text-field>
                      </v-col>
                      <v-col key="dateStart" :cols="4">
                        <v-menu
                          ref="menuDateStart"
                          v-model="menuDateStart"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="contract.startDate"
                              label="Data de inicio de contrato"
                              readonly
                              v-bind="attrs"
                              v-on="on">
                            </v-text-field>
                          </template>
                        <v-date-picker
                          v-model="contract.startDate"
                          no-title
                          scrollable>
                          <v-spacer>
                          </v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuDateStart = false">Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuDateStart.save(contract.startDate)">OK
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col key="dateEnd" :cols="4">
                        <v-menu
                          ref="menuDateEnd"
                          v-model="menuDateEnd"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="contract.endDate"
                              label="Data de Fim de contrato"
                              readonly
                              v-bind="attrs"
                              v-on="on">
                            </v-text-field>
                          </template>
                        <v-date-picker
                          v-model="contract.endDate"
                          no-title
                          scrollable>
                          <v-spacer>
                          </v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuDateEnd = false">Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuDateEnd.save(contract.endDate)">OK
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-select hide-details="auto"
                          label="Fornecedor"
                          :items="providers"
                          item-value="id"
                          item-text="designation"
                          v-model="contract.suplier">
                        </v-select>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          label="Observações"
                          v-model="contract.obs">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card-text>
                <v-container>
                  <v-text-field
                    dense
                    label="Morada"
                    v-model="deliveryLoc.address">
                  </v-text-field>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-if="typeDialog == 1"
                        label="CPE"
                        v-model="deliveryLoc.localId"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-if="typeDialog == 2"
                        label="CUI"
                        v-model="deliveryLoc.localId"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-if="typeDialog == 3 || typeDialog == 4"
                        label="ID Local"
                        v-model="deliveryLoc.localId"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select dense hide-details="auto"
                        label="Tipo"
                        :items="typeEletricity"
                        v-if="typeDialog == 1"
                        v-model="deliveryLoc.type">
                      </v-select>
                      <v-text-field dense
                        label="Escalão"
                        v-if="typeDialog != 1"
                        v-model="deliveryLoc.tier"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn
                    color="secondary"
                    @click="saveLocation">
                    Adicionar
                  </v-btn>
                  <v-data-table
                    :headers="getHeaders(typeDialog)"
                    :items="contract.deliveryLocations">
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text
          color="blue darken-1"
          @click="closeDialog">
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="saveContract">
          Gravar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  /* eslint-disable */
  name: 'contractDialog',
  props: ['showDialog','typeDialog'],
   data: () => ({
    contract: {
      id: null,
      contractNumber: null,
      startDate: "",
      endDate: "",
      provider: "",
      obs: "",
      deliveryLocations: [],
    },
    deliveryLoc: {
      address: "",
      localId: "", // LocalID, CUI, CPE , etc
      type: null,  // Bi-horario, tri-horario, etc
      tier: null,  // escalão de consumo
    },
    typeEletricity: ['Simples','Bi-Horário','Tri-Horário'],
    ruleTest: false,
    show: false,
    dateStart: [],
    dateEnd: [],
    menuDateStart: [],
    menuDateEnd: [],
    stepperIndex: 1,
    stepperText: '',
    rules: {
      required: (value) => !!value || "Preenchimento obrigatório",
    }
  }),
  methods:{
    closeDialog() {
      this.show = false;
      this.$emit('hideDialog')
    },
    saveContract() {
      let temp = {...this.contract};
      console.log(temp);
      this.$refs.contractForm.reset();
      this.contract.deliveryLocations = [];
      this.show = false;
      this.$emit('hideDialog');
    },
    saveLocation() {
      this.contract.deliveryLocations.push({...this.deliveryLoc});
      // console.log(this.contract);
      this.deliveryLoc.address = "";
      this.deliveryLoc.localId = "";
      this.deliveryLoc.type = null;
      this.deliveryLoc.tier = null;
      },
      getHeaders(val) {
        switch (val){
          case 1:
            return [
              {
                text: 'Morada',
                align: 'center',
                sortable: false,
                value: 'address'
              },
              { text: 'CPE', value: 'localId'},
              { text: 'Tipo', value: 'type'},
            ]
            break;
          case 3:
            return [
              {
                text: 'Morada',
                align: 'center',
                sortable: false,
                value: 'address'
              },
              { text: 'CUI', value: 'localId'},
              { text: 'Tipo', value: 'type'},
            ]
            break;
          case 4:
          case 2:
            return [
              {
                text: 'Morada',
                align: 'center',
                sortable: false,
                value: 'address'
              },
              { text: 'CUI', value: 'localId'},
              { text: 'Escalão', value: 'tier'},
            ]
            break;
        }
      },
  },
  watch:{
    showDialog: function(){
      this.show = this.showDialog;
    },
    typeDialog: function() {
      switch (this.typeDialog) {
        case 1:
          this.stepperText = "Dados Electricidade"
          break;
        case 2:
          this.stepperText = "Dados GN"
          break;
        case 3:
          this.stepperText = "Dados GPL"
          break;
        case 4:
          this.stepperText = "Dados Solar"
          break;
      }
    }
  },
  computed: {
    providers: function () {
      return this.$store.getters.getProviders;
    }
  },
  mounted() {
    async () => { await this.$store.dispatch("fetchProviders"); }
  }
}
</script>
