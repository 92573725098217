<script>
import { Bar } from "vue-chartjs";
import helper from "../plugins/helper";

export default {
  extends: Bar,
  mixins: [helper],
  props:['chartBarDatasets'],

  data: () => ({
    chartData: {
        labels: [],
        datasets: []
      },
    options: {
      title: {
        display: true,
        text: "Últimos 12 meses"
      },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{ticks: {beginAtZero: true}}]
        }
    }
  }),
  mounted(){
    this.renderChart(this.chartData, this.options);
    this.makeChart();
  },
  watch: {
    chartBarDatasets: function () {
      this.makeChart();
    }
  },
  methods: {
    makeChart() {
      this.chartData.datasets = [];
      this.chartData.labels = this.buildLabels();
      this.chartData.datasets = this.chartBarDatasets;
      this.$data._chart.update();
    },
    buildLabels(){
      let nMonths = 12;
      let months = [];
      for(let i=nMonths-1; i>=0; i--) {
        months.push(this.subtractMonths(i).format('MMM'));
      }
      return months;
    }
  }
}
</script>
