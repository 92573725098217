<script>/* eslint-disable */</script>
<template>
<v-container fill-height fluid>
  <v-row align="center"
    justify="center">
    <v-col align="center">
      Versão: {{ appVersion }}
      <v-img
        src="../assets/logo.png"
        lazy-src="../assets/logo.png"
        aspect-ratio="1"
        class="grey lighten-2"
        max-width="250"
        max-height="250">
      </v-img>
      <v-btn
        class="mt-5"
        color="primary"
        large
        @click.stop="clickLogin();">
        <v-icon class="mr-2">mdi-login-variant</v-icon> Iniciar sessão
      </v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
/* eslint-disable */
import {version} from '../../package'
export default {
  name: "Login",
  components: {

  },
  data: () => ({
    appVersion: version,
  }),
  methods: {
    clickLogin: function() {
      this.$emit("showLogin");
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style scoped>

</style>
