<template>
  <v-row class="fill-height">
    <v-col class="col-12 pa-5">
      <v-card>
        <v-toolbar color="primaryLight" style="z-index:10;">
          <v-toolbar-title>
            Agendamentos
          </v-toolbar-title>
          <v-btn absolute bottom right fab
            color="primary">
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined class="mr-5"
                color="grey darken-2"
                v-bind="attrs"
                v-on="on">
                <span>{{ typeToLabel[calendarType] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="calendarType = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="calendarType = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="calendarType = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small
            color="grey darken-2"
            @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small
            color="grey darken-2"
            @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title style="text-transform:capitalize;"
            v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-calendar
          locale="pt"
          ref="calendar"
          v-model="selectedDay"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="calendarType"
          event-more-text="Ver Todos"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange">
          <template v-slot:event="{ event }">
            <div>
              <b>{{event.start.split(" ").pop()}}</b>
              <span style="position:absolute;right:2px;">
                ({{event.userName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}})
              </span>
              <br>
              <span>{{event.name}}</span>
            </div>
          </template>
        </v-calendar>
      </v-card>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x>
        <v-card flat
          color="grey lighten-4"
          min-width="350px">
          <v-toolbar dark
            :color="selectedEvent.color">
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
              <!-- ao clicar aqui criar function para editar a tarefa -->
            </v-btn>
            <v-toolbar-title class="pl-0 mr-2">{{selectedEvent.name}}<br>{{selectedEvent.subject}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="routeTo('/home/'+selectedEvent.clientId)">
              <v-icon>mdi-card-account-details-outline</v-icon>
              <!-- ao clicar aqui ir para o perfil do cliente  -->
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.obs"></span><br>
            <span  v-html="selectedEvent.start"></span> <br>
            <span v-html="selectedEvent.end"></span>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary"
              @click="selectedOpen = false">
              Voltar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  /* eslint-disable */
  import $api from '../api'
  import helper from "../plugins/helper";

  export default {
    name: "Schedule",
    mixins: [helper],
    components: {

    },
    data: () => ({
      selectedDay: '',
      calendarType: 'month',
      hidden: false,
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    }),
    computed: {

    },
    methods: {
      viewDay ({ date }) {
        this.selectedDay = date;
        this.calendarType = 'day';
      },
      getEventColor (event) {
        return event.color;
      },
      setToday () {
        this.selectedDay = '';
      },
      prev () {
        this.$refs.calendar.prev();
      },
      next () {
        this.$refs.calendar.next();
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
        }
        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      },
      async updateRange ({ start, end }) {
        const events = [];
        let remoteEvents = [];
        const min = new Date(`${start.date}T00:00:00`);
        const max = new Date(`${end.date}T23:59:59`);

        try {
          let resp = await $api.getEventsBetweenDates(min.toISOString(), max.toISOString(), true);
          remoteEvents = resp.data.data.events;
        } catch(err) {
          console.log("Some error on request:"+err);
          return null;
        }

        remoteEvents.forEach(event => {
          if (event.client)
            events.push({
              name: event.client.designation,
              subject: event.subject,
              start: this.dateToYYYYMMDDHHMM(event.datehour),
              obs: event.obs,
              color: "#"+event.user.color,
              clientId: event.client.id,
              userName: event.user.name,
            });
        });
        this.events = events;
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
   },
    watch: {

    },
    mounted() {
      this.$refs.calendar.checkChange();
    },
   }
</script>

<style>
  #calendario {
    display: block;
    min-height: unset;
    padding: 8px;
    background-color: #f1f1ef
  }
  .v-event {
    width: 100% !important;
    height: auto !important;
    padding: 2px !important;
  }
  .v-calendar-weekly__day {
    padding: 2px !important;
  }
</style>