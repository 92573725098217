<template> 
  <v-col cols="12">
    <v-row>
      <v-col cols="9">
        <v-card>
          <BarChart
            :chartBarDatasets="graphGPLBarDatasets">
          </BarChart>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <PieChart
            :chartDatasets="graphGPLDatasets"
            :chartLabels="graphGPLLabels">
          </PieChart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cos="12">
        <v-data-table
          v-model="selectedGPL"
          :headers="headersGPL"
          :items="locaisGPL"
          :single-select="false"
          show-expand
          item-key="name"
          class="elevation-1"
          show-select>
          <template v-slot:expanded-item="{headers, item}">
            <td
            :colspan="headers.length"
            class="text-center justify-center">
              Informações adicionais acerca de {{item.name}}
            </td>
          </template>
          <template v-slot:[`item.data`]="{ item }">
              <v-chip color="transparent">
                {{item.data.reduce( (a,b) => a + b, 0)}}
              </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import helperComponents from "../plugins/helperComponents";

export default {
  name: "GplTab",
  props: ['locaisGPL'],
  mixins: [helperComponents],
  components: {
      PieChart,
      BarChart,
    },
  data: () => ({
    selectedGPL: [],
    graphGPLLabels: [],
    graphGPLBarDatasets: [],
    graphGPLDatasets: [],
    headersGPL: [
        {
          text: 'Local de Entrega',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        { text: 'CUI', value: 'cui'},
        { text: 'Escalão', value: 'escalao'},
        { text: 'Consumo', value: 'data[11]'},
        { text: 'Consumo Total', value: "data"}
      ],
  }),
  watch: {
    locaisGPL: function() {
      this.selectedGPL = [...this.locaisGPL];
    },
    selectedGPL: function(val) {
      this.graphGPLDatasets = this.buildDatasets(val);
      this.graphGPLLabels = this.buildLabels(val);
      this.graphGPLBarDatasets = this.buildBarDatasets(val);
    }
  },
  mounted() {
    this.selectedGPL = [...this.locaisGPL];
  }
}
</script>
