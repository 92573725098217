<template>
  <v-container fluid>
    <v-row dense class="fill-height" no-gutters>
      <v-col class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
        <v-row no-gutters>
          <v-progress-linear indeterminate :active="loaders.searchClient"></v-progress-linear>
          <v-text-field dense clearable solo hide-details single-line
            prepend-inner-icon="mdi-magnify"
            placeholder="Procurar..."
            @input="debounceSearch('nifDesignationObs', $event)">
            <template v-slot:append>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn tile icon :color="(
                    searchClients.activity ||
                    searchClients.country ||
                    searchClients.district ||
                    searchClients.city ||
                    searchClients.area
                    ?'accent':'initial'
                  )"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-filter-menu-outline</v-icon>
                  </v-btn>
                  <v-btn tile icon
                    @click.stop="printSearch">
                    <v-icon>mdi-printer-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!--<v-list-item>
                    <v-select dense hide-details clearable multiple
                      v-model="searchClients.service"
                      :items="[...services]"
                      return-object
                      item-text="designation"
                      item-value="id"
                      label="Serviços">
                    </v-select>
                  </v-list-item>-->
                  <v-list-item>
                    <v-text-field dense clearable hide-details single-line
                      label="Atividade" placeholder="Atividade"
                      @input="debounceSearch('activity', $event)">
                    </v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete dense hide-details clearable
                      v-model="searchClients.country"
                      @change="searchClients.searchChanged='country'"
                      :items="countries"
                      item-text="designation"
                      item-value="id"
                      label="País">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete dense hide-details clearable
                      v-model="searchClients.district"
                      @change="searchClients.searchChanged='district'"
                      :items="districts(searchClients.country)"
                      item-text="designation"
                      item-value="id"
                      label="Distrito">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete dense hide-details clearable
                      v-model="searchClients.city"
                      @change="searchClients.searchChanged='city'"
                      :items="cities(searchClients.district)"
                      item-text="designation"
                      item-value="id"
                      label="Concelho">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete dense hide-details clearable
                      v-model="searchClients.area"
                      @change="searchClients.searchChanged='area'"
                      :items="areas(searchClients.city)"
                      item-text="designation"
                      item-value="id"
                      label="Freguesia">
                    </v-autocomplete>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
          <v-list dense style="width:100%; max-height:calc(100vh - 126px);overflow-y:auto">
            <v-btn style="width:100%" class="mb-2" color="primary"
              @click.stop="showClientForm()">
              Adicionar
            </v-btn>
            <div v-if="!clients.length">Sem clientes</div>
            <v-list-item-group color="primary"
              v-model="selectedClient">
              <v-virtual-scroll
                bench="30"
                :items="clients"
                :item-height="50"
                height="dynamic"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :value="item.id">
                    <v-hover v-slot="{ hover }">
                        <v-list-item-content :ref="'c'+item.id">
                          <v-list-item-title v-text="item.designation"></v-list-item-title>
                          <v-icon v-show="hover"
                            style="position:absolute;right:10px"
                            @click.stop="deleteClient(item)" color="red">
                            mdi-delete
                          </v-icon>
                          <v-list-item-subtitle :color="'green'">métricas rápidas</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-hover>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>
        </v-row>
      </v-col>
      <v-col class="col-xs-12 col-sm-8 col-md-8 col-lg-9" v-if="selectedClient">
        <v-container style="max-height:calc(100vh - 88px); overflow-y:auto">
          <v-card color="secondary">
            <v-card-title class="primary--text">
              {{clientData.designation}}
              <div class="text-right mt-5 mr-5" style="position:absolute; right:0">
                <v-btn fab class="ml-2"
                  color="primary"
                  v-if="tab==1"
                  @click.stop="showClientForm(clientData)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn fab class="ml-2"
                  color="primary"
                  v-if="tab==2"
                  @click.stop="showEventForm()">
                  <v-icon>mdi-calendar-plus</v-icon>
                </v-btn>
                <v-btn fab class="ml-2"
                  color="primary"
                  @click.stop="consumptionFormVisible = true"
                  v-if="tab>2">
                  <v-icon>mdi-plus-network-outline</v-icon>
                </v-btn>
                <v-btn fab class="ml-2"
                  color="primary"
                  v-if="tab>2">
                  <v-icon>mdi-note-plus-outline</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-progress-linear indeterminate :active="loaders.clientInfo"></v-progress-linear>
            <v-tabs grow show-arrows
              v-model="tab"
              background-color="transparent"
              color="primary">
              <v-tab
                v-for="item in tabitems"
                :key="item">
                {{item}}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item ref="tabResumo">
                <div style="min-height:400px">
                  <v-row dense no-gutters>
                    <v-col cols="6">
                      <div class="ma-5"
                        v-if="treeviewItems.length==0">
                        Sem contratos
                      </div>
                      <v-treeview
                        :items="treeviewItems"
                        hoverable
                        open-on-click
                        dense
                        color="secondary">
                      </v-treeview>
                    </v-col>
                    <v-col cols="6">
                      <div class="ma-5">
                        <v-btn block class="ma-2">Acção rápida 1</v-btn>
                        <v-btn block class="ma-2">Acção rápida 2</v-btn>
                        <v-btn block class="ma-2">Acção rápida 3</v-btn>
                        <v-btn block class="ma-2">Acção rápida 4</v-btn>
                        <v-btn block class="ma-2">Acção rápida 5</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabDados">
                <div style="min-height:400px">
                  <v-container v-if="clientData.fullAddress">
                    <v-row>
                      <v-col cols="12">
                        <v-expansion-panels v-model="clientDataExpanded">
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              Dados
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col class="col-xs-12 col-md-4 col-lg-3">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.taxNumber"
                                    label="NIF">
                                  </v-text-field>
                                </v-col>
                                <v-col class="col-xs-12 col-md-8 col-lg-9">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.designation"
                                    label="Designação">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.activity"
                                    label="Actividade">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.tradeName"
                                    label="Designação Comercial">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.phone1"
                                    label="Telefone">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.phone2"
                                    label="Telefone">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.webpage"
                                    label="Webpage">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.email1"
                                    label="Email">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.email2"
                                    label="Email">
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              Morada
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.country?clientData.fullAddress.country.designation:''"
                                    label="País">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.district?clientData.fullAddress.district.designation:''"
                                    label="Distrito">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.city?clientData.fullAddress.city.designation:''"
                                    label="Concelho">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.area?clientData.fullAddress.area.designation:''"
                                    label="Freguesia">
                                  </v-text-field>
                                  </v-col>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.street1"
                                    label="Rua">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="6">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.street2"
                                    label="Rua">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="4">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.postalCode"
                                    label="Código-Postal"
                                    maxlength="8">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="4">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.latitude"
                                    label="Latitude">
                                  </v-text-field>
                                </v-col>
                                <v-col :cols="4">
                                  <v-text-field dense readonly hide-details
                                    :value="clientData.fullAddress.longitude"
                                    label="Longitude">
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              Locais de Entrega
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col :cols="12">
                                  Testing...
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <template v-slot:default="{ open }">
                                <v-row no-gutters>
                                  <v-col cols="8">
                                    Contactos
                                  </v-col>
                                  <v-col cols="4" class="text-center">
                                    <v-fade-transition leave-absolute>
                                      <span v-if="open">
                                        <v-btn x-small color="primary"
                                          @click.stop="showContactForm()">
                                          Adicionar
                                        </v-btn>
                                      </span>
                                    </v-fade-transition>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-data-table dense show-expand
                                :headers="[
                                  {text:'Nome', value:'name'},
                                  {text:'Telefone', value:'phone1'},
                                  {text:'Telemóvel', value:'phone2'},
                                  {text:'E-mail', value:'email'},
                                  {text: '', value:'actions'}]"
                                :items="clientData.contacts"
                                item-key="id"
                                single-expand>
                                <template v-slot:expanded-item="{ headers, item }">
                                  <td :colspan="headers.length">
                                    <span>Cargo: {{ item.position }}</span><br>
                                    <span>OBS: {{ item.obs }}</span>
                                  </td>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                  <v-icon small
                                    class="mr-2"
                                    @click="showContactForm(item)">
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon small
                                    @click="deleteContact(item)">
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <template v-slot:no-data>
                                  Nenhum contacto
                                </template>
                              </v-data-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              Observações
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col :cols="12">
                                  {{clientData.obs}}
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabOcorrencias">
                <div style="min-height:400px">
                  <v-row>
                    <v-col>
                      <v-card>
                        <v-card-title>
                          <v-row dense>
                            <v-col :cols="2">
                              <v-switch dense hide-details
                                style="margin-top:4px"
                                :prepend-icon="(clientData && clientData.events && clientData.events.length==expandedEvents.length ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all')"
                                @change="(v) => expandAllEvents(clientData.events, v)">
                              </v-switch>
                            </v-col>
                            <v-col :cols="3">
                              <v-select dense hide-details clearable
                                v-model="searchEventService"
                                :items="[...services]"
                                return-object
                                item-text="designation"
                                item-value="id"
                                label="Serviços">
                              </v-select>
                            </v-col>
                            <v-col :cols="6">
                              <v-text-field flat clearable dense
                                v-model="searchEventText"
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                label="Pesquisar">
                              </v-text-field>
                            </v-col>
                            <v-col :cols="1">
                              <v-btn tile icon
                                :loading="loaders.printEvents"
                                @click.stop="printEvents(clientData)">
                                <v-icon>mdi-printer-outline</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-data-table dense show-expand
                          :headers="[
                            {text: 'Data/Hora', value: 'created_at', align: 'center'},
                            {text: 'Serviço', value: 'service'},
                            {text: 'Assunto', value: 'subject'},
                            {text: 'Atribuido a', value: 'user.name'},
                            {text: 'Criado por', value: 'createdBy.name'},
                            {text: 'Obs', value: 'obs'},
                            {text: '', value: 'actions'}]"
                          :search="searchEvent"
                          :expanded="expandedEvents"
                          :custom-filter="customEventSearch"
                          :items="clientData.events"
                          :footer-props="{
                            'items-per-page-options': [10, 20, 50, 100, -1],
                            'items-per-page-text': 'Linhas por página'
                          }"
                          item-key="id"
                          no-data-text="Sem Ocorrências">
                          <!--<template v-slot:top="{ pagination, options, updateOptions }">
                            <v-data-footer
                              :pagination="pagination"
                              :options="options"
                              @update:options="updateOptions"
                              items-per-page-text="Linhas por página"/>
                          </template>-->
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <span>{{ item.obs }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.created_at`]="{ item }">
                            <span :title="item.scheduled ? dateToYYYYMMDDHHMM(item.datehour) : ''"
                              :class="item.scheduled ? 'makeGreen':''"
                              v-text="(new Date(item.datehour) < new Date(item.created_at)) ?
                                    dateToYYYYMMDDHHMM(item.datehour)
                                    :
                                    dateToYYYYMMDDHHMM(item.created_at)">
                            </span>
                          </template>
                          <template v-slot:[`item.service`]="{ item }">
                            {{ getSafe(()=>item.service?item.service.designation:'Todos') }}
                          </template>
                          <template v-slot:[`item.obs`]="{ item }">
                            {{ item.obs ? item.obs.slice(0, 20) + "..." : ""}}
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2"
                              :disabled="!item.scheduled"
                              :color="item.completed?'green':''"
                              @click="markEventComplete(item)">
                              mdi-check-bold
                            </v-icon>
                            <v-icon small
                              class="mr-2"
                              @click="showEventForm(item)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small
                              class="mr-2"
                              @click="deleteEvent(item)">
                              mdi-delete
                            </v-icon>
                          </template>
                          <template v-slot:no-data>
                            Nenhuma ocorrência
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabEletricidade">
                <div
                  style="min-height:400px"
                  v-if="isActive.electricity">
                  <electricity-tab
                    :locaisElectricity="locaisElectricity">
                  </electricity-tab>
                </div>
                <div class="text-center"
                  style="min-height:400px"
                  v-if="!isActive.electricity">
                  <v-btn
                    class="mt-5 mx-auto"
                    color="secondary"
                    large
                    @click="contractDialog = true; typeContract = 1">
                      Adicionar Contrato
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabGN">
                <div style="min-height:400px"
                  v-if="isActive.gn">
                  <gn-tab
                    :locaisGn="locaisGN">
                  </gn-tab>
                </div>
                <div class="text-center"
                  style="min-height:400px"
                  v-if="!isActive.gn">
                  <v-btn
                    class="mt-5 mx-auto"
                    color="secondary"
                    large
                    @click="contractDialog = true; typeContract = 2">
                      Adicionar Contrato
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabGPL">
                <div style="min-height:400px"
                  v-if="isActive.gpl">
                  <gpl-tab
                    :locaisGPL="locaisGPL">
                  </gpl-tab>
                </div>
                <div class="text-center"
                  style="min-height:400px"
                  v-if="!isActive.gpl">
                  <v-btn
                    class="mt-5 mx-auto"
                    color="secondary"
                    large
                    @click="contractDialog = true; typeContract = 3">
                      Adicionar Contrato
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabSolar">
                <div
                  class="text-center"
                  style="min-height:400px"
                  v-if="!isActive.solar">
                  <v-btn
                    class="mt-5 mx-auto"
                    color="secondary"
                    large
                    @click="contractDialog = true; typeContract = 4">
                      Adicionar Contrato
                  </v-btn>
                </div>
                <div style="min-height:400px"
                  v-if="isActive.solar">
                  <solar-tab
                    :locaisSolar="locaisSolar">
                  </solar-tab>
                </div>
              </v-tab-item>
              <v-tab-item ref="tabConsultadoria">
                <div
                  v-if="!isActive.consulting"
                  style="min-height:400px">
                  <v-card-text>
                    Não existe serviço de consultadoria ativo para este cliente
                  </v-card-text>
                  <v-btn
                     @click="isActive.consulting=!isActive.consulting"
                    rounded
                    color="secondary">
                    Adicionar serviço
                  </v-btn>
                </div>
                <v-card flat
                  v-if="isActive.consulting"
                  color="transparent">
                  <v-card-text>
                    <v-form :readonly="!editClient">
                      <v-row dense no-gutters>
                        <v-col>
                          <v-row dense no-gutters>
                            <v-col cols="12">
                              <v-btn
                                @click="isActive.consulting=!isActive.consulting"
                                rounded
                                color="secondary">
                                Remover serviço (DEMO ONLY!)
                              </v-btn>
                              <v-data-table
                                v-model="selectedLocal"
                                :headers="headersElectricity"
                                :items="locais"
                                :single-select="false"
                                item-key="name"
                                class="elevation-1">
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-col>
      <v-col class="col-xs-12 col-md-8 col-lg-9" v-else>
        <v-container fluid>
          <v-row dense no-gutters>
            <v-col :cols="12">
              Nenhum cliente selecionado
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="6">
              <v-card>
                <v-card-title>Próximos agendamentos</v-card-title>
                <v-card-text style="max-height: calc(100vh - 276px); overflow-y: auto;">
                  <div v-if="!me.events.length">
                    Sem agendamentos
                  </div>
                  <div v-for="event in me.events" :key="event.id" class="mb-3">
                    <span class="text-caption" :class="isBeforeToday(event.datehour)?'red--text':''">
                      <b>{{ dateToYYYYMMDDHHMM(event.datehour) }}</b>
                      <span class="text-caption black--text float-right">
                        ({{ (isBeforeToday(event.datehour) ?
                              'atraso de '+diffDatesDays(event.datehour, now) :
                              'em '+diffDatesDays(now, event.datehour))}} dias)
                      </span>
                    </span>
                    <a href="javascript:;" class="text-caption text-decoration-none d-block font-weight-bold" @click="selectedClient=event.client.id">
                      {{ event.client.designation }}
                    </a>
                    <h5><i>{{ event.subject }}</i></h5>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn to="/Schedule" color="primary">Ver agenda</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card>
                <v-card-title>Alguma métrica</v-card-title>
                <v-sparkline
                  :labels=labels
                  :value=values
                  color="primary"
                  line-width="2"
                  padding="16">
                </v-sparkline>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary">Ver detalhes</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <!--------CLIENT FORM----->
    <v-dialog
      v-if="clientFormVisible"
      width="800"
      v-model="clientFormVisible">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{client.id ? 'Editar' : 'Adicionar'}} Cliente </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="clientForm" lazy-validation>
            <v-row dense>
              <v-col :cols="3">
                <v-text-field dense
                  v-model.number="client.taxNumber"
                  :error-messages="nifError"
                  type="number"
                  label="NIF">
                </v-text-field>
              </v-col>
              <v-col :cols="9">
                <v-text-field dense hide-details
                  v-model="client.designation"
                  :rules="[rules.required]"
                  label="Designação">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.activity"
                  label="Actividade">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense
                  v-model="client.tradeName"
                  :error-messages="nifError"
                  label="Designação Comercial">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="6">
                <v-autocomplete dense hide-details clearable
                  v-model="client.fullAddress.country"
                  @change="clientChanged='country'"
                  :items="countries"
                  item-text="designation"
                  item-value="id"
                  :search-input.sync="searchSync.country"
                  label="País">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title v-if="searchSync.country">
                        <v-btn
                          color="primary"
                          :loading=loaders.submitCountry
                          @click.stop="submitCountry">
                          Adicionar '{{searchSync.country}}'
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{noDataMessage}}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col :cols="6">
                <v-autocomplete dense hide-details clearable
                  v-model="client.fullAddress.district"
                  @change="clientChanged='district'"
                  :items="districts(client.fullAddress.country)"
                  item-text="designation"
                  item-value="id"
                  :search-input.sync="searchSync.district"
                  label="Distrito">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title v-if="searchSync.district">
                        <v-btn
                          color="primary"
                          :loading=loaders.submitDistrict
                          @click.stop="submitDistrict">
                          Adicionar '{{searchSync.district}}'
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{noDataMessage}}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                </v-col>
              <v-col :cols="6">
                <v-autocomplete dense hide-details clearable
                  v-model="client.fullAddress.city"
                  @change="clientChanged='city'"
                  :items="cities(client.fullAddress.district)"
                  item-text="designation"
                  item-value="id"
                  :search-input.sync="searchSync.city"
                  label="Concelho">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title v-if="searchSync.city">
                        <v-btn
                          color="primary"
                          :loading=loaders.submitCity
                          @click.stop="submitCity">
                          Adicionar '{{searchSync.city}}'
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{noDataMessage}}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col :cols="6">
                <v-autocomplete dense hide-details clearable
                  v-model="client.fullAddress.area"
                  @change="clientChanged='area'"
                  :items="areas(client.fullAddress.city)"
                  item-text="designation"
                  item-value="id"
                  :search-input.sync="searchSync.area"
                  label="Freguesia">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title v-if="searchSync.area">
                        <v-btn
                          color="primary"
                          :loading=loaders.submitArea
                          @click.stop="submitArea">
                          Adicionar '{{searchSync.area}}'
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{noDataMessage}}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.fullAddress.street1"
                  label="Rua">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.fullAddress.street2"
                  label="Rua">
                </v-text-field>
              </v-col>
              <v-col :cols="4">
                <v-text-field dense hide-details
                  v-model="client.fullAddress.postalCode"
                  label="Código-Postal"
                  maxlength="8">
                </v-text-field>
              </v-col>
              <v-col :cols="4">
                <v-text-field dense hide-details
                  v-model="client.fullAddress.latitude"
                  @dblclick="pasteCoords"
                  :rules="[rules.latitude]"
                  label="Latitude">
                </v-text-field>
              </v-col>
              <v-col :cols="4">
                <v-text-field dense hide-details
                  v-model="client.fullAddress.longitude"
                  @dblclick="pasteCoords"
                  :rules="[rules.longitude]"
                  label="Longitude">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.phone1"
                  label="Telefone">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.phone2"
                  label="Telefone Alternativo">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.email1"
                  :rules="[rules.email]"
                  type="email"
                  label="E-mail">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="client.email2"
                  :rules="[rules.email]"
                  type="email"
                  label="E-mail Alternativo">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-text-field dense hide-details
                  v-model="client.webpage"
                  label="Webpage">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-textarea dense hide-details
                  rows="4"
                  v-model="client.obs"
                  label="Observações">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="blue darken-1"
            @click.stop="hideClientForm">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="submitClientForm">
            Gravar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirmation
      :showDialog = "showDialogDeleteClient"
      :question = "'Pretende eliminar o cliente?'"
      :message = "'Não será possível recuperá-lo após eliminar'"
      @hideDialog = "showDialogDeleteClient=false"
      @responseDialog = "handleReponseDeleteClient">
    </dialog-confirmation>
    <!-------------------------->
    <!--------CONTACT FORM----->
    <v-dialog width="500"
      v-if="contactFormVisible"
      v-model="contactFormVisible">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{contact.id ? 'Editar' : 'Adicionar'}} Contacto </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="contactForm" lazy-validation>
            <v-row dense>
              <v-col :cols="12">
                <v-text-field dense hide-details
                  v-model="contact.name"
                  :rules="[rules.required]"
                  label="Nome">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-text-field dense hide-details
                  v-model="contact.position"
                  label="Cargo">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="contact.phone1"
                  label="Telefone">
                </v-text-field>
              </v-col>
              <v-col :cols="6">
                <v-text-field dense hide-details
                  v-model="contact.phone2"
                  label="Telemóvel">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-text-field dense hide-details
                  v-model="contact.email"
                  :rules="[rules.email]"
                  type="email"
                  label="Email">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-textarea dense hide-details
                  v-model="contact.obs"
                  label="Observações">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="blue darken-1"
            @click.stop="hideContactForm">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="submitContactForm">
            Gravar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirmation
      :showDialog = "showDialogDeleteContact"
      :question = "'Pretende eliminar o contacto?'"
      :message = "'Não será possível recuperá-lo após eliminar'"
      @hideDialog = "showDialogDeleteContact=false"
      @responseDialog = "handleReponseDeleteContact">
    </dialog-confirmation>
    <!----------------------->
    <!--------EVENTS FORM----->
    <v-dialog width="500"
      v-if="eventFormVisible"
      v-model="eventFormVisible">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{event.id ? 'Editar' : 'Adicionar'}} Ocorrência </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="eventForm" lazy-validation>
            <v-row dense>
              <v-col :cols="6">
                <v-checkbox hide-details
                  :disabled="scheduled < now"
                  v-model="event.scheduled"
                  label="Agendamento"
                ></v-checkbox>
              </v-col>
              <v-col :cols="6">
                <v-checkbox hide-details
                  :disabled="!event.scheduled"
                  v-model="event.completed"
                  label="Concluído"
                ></v-checkbox>
              </v-col>
              <v-col :cols="12">
                <v-select hide-details dense
                  :items="users"
                  item-value="id"
                  item-text="name"
                  item-disabled="blocked"
                  label="Utilizador"
                  v-model="event.user"
                  :rules="[rules.required]">
                </v-select>
              </v-col>
              <v-col :cols="6">
                <v-menu offset-y
                  ref="eventDatePicker"
                  v-model="eventDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="event.date"
                  transition="scale-transition"
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field readonly hide-details dense
                      v-model="event.date"
                      label="Data da Ocorrência"
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker no-title scrollable
                    @click:date="$refs.eventDatePicker.save(event.date)"
                    v-model="event.date">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"
                      @click="eventDatePicker = false">Cancel
                    </v-btn>
                    <v-btn text color="primary"
                      @click="$refs.eventDatePicker.save(event.date)">OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="6">
                <v-menu offset-y
                  ref="eventHourPicker"
                  v-model="eventHourPicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  @input="value => value && $refs.picker && ($refs.picker.selectingHour = true)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field readonly hide-details dense
                      v-model="event.hour"
                      label="Hora da Ocorrência"
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-time-picker no-title scrollable format="24hr"
                    ref="picker"
                    @click:minute="$refs.eventHourPicker.save(event.hour)"
                    v-model="event.hour">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"
                      @click="eventHourPicker = false">Cancel
                    </v-btn>
                    <v-btn text color="primary"
                      @click="$refs.eventHourPicker.save(event.hour)">OK
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col :cols="12">
                <v-select dense hide-details clearable
                  v-model="event.service"
                  :items="[
                    {id: '0', designation: 'Todos'},
                    ...services
                  ]"
                  item-text="designation"
                  item-value="id"
                  label="Serviços">
                </v-select>
              </v-col>
              <v-col :cols="12">
                <v-text-field dense hide-details
                  v-model="event.subject"
                  :rules="[rules.required]"
                  label="Assunto">
                </v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-textarea dense hide-details
                  v-model="event.obs"
                  label="Observações">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="blue darken-1"
            @click.stop="hideEventForm">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="submitEventForm">
            Gravar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirmation
      :showDialog = "showDialogDeleteEvent"
      :question = "'Pretende eliminar a ocorrência?'"
      :message = "'Não será possível recuperá-la após eliminar'"
      @hideDialog = "showDialogDeleteEvent=false"
      @responseDialog = "handleReponseDeleteEvent">
    </dialog-confirmation>
    <!----------------------->
    <!--CONSUMPTION FORM-->
      <v-dialog
        v-if="consumptionFormVisible"
        v-model="consumptionFormVisible"
        width="500">
        <v-card>
          <v-card-title>
            Adicionar Consumo
          </v-card-title>
          <v-card-text>
            <v-form ref="consumptionForm" lazy-validation>
              <v-select
                v-if="tab == 3"
                :items="locaisElectricity"
                item-value="drop"
                item-text="name"
                label="Local de Entrega"
                v-model="consumption.drop">
              </v-select>
              <v-select
                v-if="tab == 4"
                :items="locaisGPL"
                item-value="id"
                item-text="name"
                label="Local de Entrega"
                v-model="consumption.drop">
              </v-select>
              <v-select
                v-if="tab == 5"
                :items="locaisGN"
                item-value="id"
                item-text="name"
                label="Local de Entrega"
                v-model="consumption.drop">
              </v-select>
              <v-select
                v-if="tab == 6"
                :items="locaisSolar"
                item-value="id"
                item-text="name"
                label="Local de Entrega"
                v-model="consumption.drop">
              </v-select>
              <v-row dense>
                <v-col :cols="6">
                  <v-text-field dense hide-details
                    v-model="consumption.value"
                    :rules="[rules.required]"
                    label="Consumo">
                  </v-text-field>
                </v-col>
                <v-col :cols="6">
                  <v-text-field dense hide-details
                    v-model="consumption.price"
                    :rules="[rules.required]"
                    label="Preço">
                  </v-text-field>
                </v-col>
                <v-col key="dateStart" :cols="6">
                  <v-menu offset-y
                    ref="consumptionDateStart"
                    v-model="consumptionDateStart"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field readonly hide-details
                        v-model="consumption.start"
                        label="Data de inicio de contrato"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                  <v-date-picker no-title scrollable
                    v-model="consumption.start">
                    <v-spacer>
                    </v-spacer>
                      <v-btn text color="primary"
                        @click="consumptionDateStart = false">Cancel
                      </v-btn>
                      <v-btn text color="primary"
                        @click="$refs.consumptionDateStart.save(consumption.start)">OK
                      </v-btn>
                  </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col key="dateEnd" :cols="6">
                  <v-menu offset-y
                    ref="consumptionDateEnd"
                    v-model="consumptionDateEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field readonly hide-details
                        v-model="consumption.end"
                        label="Data de Fim de contrato"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                  <v-date-picker no-title scrollable
                    v-model="consumption.end">
                    <v-spacer>
                    </v-spacer>
                      <v-btn text color="primary"
                        @click="consumptionDateEnd = false">Cancel
                      </v-btn>
                      <v-btn text color="primary"
                        @click="$refs.consumptionDateEnd.save(consumption.end)">OK
                      </v-btn>
                  </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text color="blue darken-1"
              @click.stop="consumptionFormVisible = false">
              Cancelar
            </v-btn>
            <v-btn color="primary"
              @click.stop="submitConsumption(tab)">
              Gravar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!---->
    <contract-dialog
      :showDialog="contractDialog"
      :typeDialog="typeContract"
      @hideDialog="contractDialog = false">
    </contract-dialog>

  </v-container>
</template>

<script>
  /* eslint-disable */
  import {toUpperCase, doHardWork} from '../worker-api'
  import $api from '../api'
  import helper from "../plugins/helper";
  import { EventBus } from '../plugins/eventBus';
  import PdfContent from "../components/PdfContent";
  import DialogConfirmation from "../components/DialogConfirmation";
  import PieChart from "../components/PieChart.vue";
  import BarChart from "../components/BarChart.vue";
  // import clientes from "@/dados.json";
  import ElectricityTab from "../components/ElectricityTab"
  import GplTab from "../components/GplTab"
  import ContractDialog from "../components/ContractDialog.vue";
  import GnTab from "../components/GnTab";
  import SolarTab from '../components/SolarTab.vue';

  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  pdfMake.tableLayouts = {
    exampleLayout: {
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return (i === node.table.headerRows) ? 2 : 1;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return i === 1 ? 'black' : '#aaa';
      },
      paddingLeft: function (i) {
        return i === 0 ? 0 : 8;
      },
      paddingRight: function (i, node) {
        return (i === node.table.widths.length - 1) ? 0 : 8;
      }
    }
  };

  export default {
    name: "Home",
    mixins: [helper],
    components: {
      DialogConfirmation,
      PieChart,
      BarChart,
      ElectricityTab,
      GplTab,
      ContractDialog,
      GnTab,
      SolarTab,
      PdfContent,
    },
    data: () => ({
      isActive: {
        electricity: false,
        gpl: false,
        gn: false,
        solar: false,
        consulting: false,
      },
      services: [
        {id: '1', designation: 'Eletricidade'},
        {id: '2', designation: 'Gás Natural'},
        {id: '3', designation: 'GPL'},
        {id: '4', designation: 'Fotovoltaico '},
        {id: '5', designation: 'Consultadoria'}
      ],
      forceClientUpdate: 0, //force cached computed to refresh
      providers: [],
      selectedClient: 0,
      selectedLocal: [],
      contracts: [],
      tab: null,
      //searchClient: null,
      editClient: false,
      clientDataExpanded: 0,
      contractDialog: false,
      clientFormVisible: false,
      client: null,
      clientChanged: false,
      contactFormVisible: false,
      contact: null,
      event: null,
      eventFormVisible: false,
      eventDatePicker: "",
      eventHourPicker: "",
      consumptionFormVisible: false,
      consumption: {
        value: null,
        price: null,
        start: "",
        end: "",
        drop: "",
        service: ""
      },
      dateStart: "",
      dateEnd: "",
      consumptionDateStart: "",
      consumptionDateEnd: "",
      rules: {
        required: (value) => !!value ?? "Preenchimento obrigatório",
        email: (value) => {
          let regex = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return regex.test(value) ?? "Formato inválido";
        },
        latitude: (value) => {
          let regex = /^$|^[\+-]?(([1-8]?\d)(\.\d{1,})?|90)\D*[NSns]?$/;
          return (!value || regex.test(value)) ?? "Formato inválido";
        },
        longitude: (value) => {
          let regex = /^$|^[\+-]?((1[0-7]\d|[1-9]?\d)(\.\d{1,})?|180)\D*[EWew]?$/;
          return (!value || regex.test(value)) ?? "Formato inválido";
        },
      },
      showDialogDeleteClient: false,
      showDialogDeleteContact: false,
      showDialogDeleteEvent: false,
      contractDialog: false,
      typeContract: 0,
      locaisElectricity: [],
      locaisGPL: [],
      locaisGN: [],
      locaisSolar: [],
      tabitems: ['Resumo', 'Dados', 'Ocorrências', 'Eletricidade', 'Gás Natural', 'GPL', 'Fotovoltaico', 'Consultadoria'],
      treeviewItems: [],
      debounce: null,
      loaders: {
        searchClient: false,
        clientInfo: false,
        submitCountry: false,
        submitDistrict: false,
        submitCity: false,
        submitArea: false,
        printEvents: false,
      },

      labels: ['00:00','03:00','06:00','09:00','12:00','15:00','18:00','21:00'],
      values: [200,675,410,390,310,460,250,240],
      searchEventText: null,
      searchEventService: null,
      searchClients: {
        searchChanged: false,
        nifDesignationObs: null,
        activity: null,
        service: null,
        country: null,
        district: null,
        city: null,
        area: null,
      },
      expandedEvents: [],
      nifError: "",
      searchSync: {
        country: null,
        district: null,
        city: null,
        area: null
      }
    }),
    computed: {
      noDataMessage() {
        return "Sem resultados";
      },
      me() {
        return this.$store.getters.getMe;
      },
      clients() {
        this.forceClientUpdate; //force cached computed to refresh
        return this.$store.getters.getClients({...this.searchClients});
      },
      clientData() {
        this.forceClientUpdate; //force cached computed to refresh
        return this.$store.getters.getClient(this.selectedClient);
      },
      users() {
        return this.$store.getters.getUsers;
      },
      now() {
        this.event?.date; //referencing so to obtain reactiveness
        this.event?.hour; //referencing so to obtain reactiveness
        return new Date().getTime();
      },
      scheduled() {
        if(!this.event.date || !this.event.hour) return 0;
        return new Date(this.event.date + " " + this.event.hour).getTime();
      },
      searchEvent() {
        let result = this.searchEventText ?? null;
        if(this.searchEventService && this.searchEventService.id != 0) {
          result = [result, this.searchEventService.designation].join(' ').trim();
        }
        return result;
      },
      countries() {
        if (this.clientChanged == 'country') {
          this.client.fullAddress.district = null;
          this.client.fullAddress.city = null;
          this.client.fullAddress.area = null;
        }
        if (this.searchClients.searchChanged == 'country') {
          this.searchClients.district = null;
          this.searchClients.city = null;
          this.searchClients.area = null;
        }

        return this.$store.getters.getCountries;
      },
      districts() {
        return country => {
          if (this.clientChanged == 'district') {
            this.client.fullAddress.city = null;
            this.client.fullAddress.area = null;
          }
          if (this.searchClients.searchChanged == 'district') {
            this.searchClients.city = null;
            this.searchClients.area = null;
          }
          return country ? this.$store.getters.getDistrictsByCountry(country) : this.$store.getters.getDistricts;
        }
      },
      cities() {
        return district => {
          if (this.clientChanged == 'city') {
            this.client.fullAddress.area = null;
          }
          if (this.searchClients.searchChanged == 'city') {
            this.searchClients.area = null;
          }
          return district ? this.$store.getters.getCitiesByDistrict(district) : this.$store.getters.getCities;
        }
      },
      areas() {
        this.forceAreasUpdate;
        return city => {
          return city ? this.$store.getters.getAreasByCity(city) : this.$store.getters.getAreas;
        }
      }
    },
    methods: {
      expandAllEvents(items, status) {
        if (status) {
          this.expandedEvents = items
        } else {
          this.expandedEvents = []
        }
      },
      customEventSearch (value, search, item) {
        let itemNormalized = JSON.stringify(item).toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
        let searchNormalized = search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').split(' ');

        return searchNormalized.every(v => itemNormalized.includes(v));
      },
      //dynamicaly build the entire treeview
      buildTreeView(drops) {
        let treeview = [];
        let index = 1;
        drops.forEach( element => { //goes through every drop and builds the first layer of the treeview
          let tempElement = {
            id: index,
            name: element.service.designation,
            children: [],
          };
          if (!treeview.some( val => val.name == element.service.designation)) {
            treeview.push(tempElement)
            index++;
          };
          treeview.forEach( val => { // goes through the first layer of the treeview and populates it based on the drop being iterated on
            let tempChild = {};
            switch (element.service.id) {
              case "1":
                tempChild = {
                  id: index,
                  name: element.contract_lines[0].drop.fullAddress.street1,
                  children: [
                    {id: index, name: 'CPE: ' + element.contract_lines[0].drop.identifications.cpe},
                    {id: index+1, name: 'Tipo: ' + element.contract_lines[0].otherInfo},
                    {id: index+2, name: 'Fornecedor: ' + element.provider.designation}
                  ],
                };
              break;
              case "2":
                tempChild = {
                    id: index,
                    name: element.contract_lines[0].drop.fullAddress.street1,
                    children: [
                      {id: index, name: 'CUI: ' + element.contract_lines[0].drop.identifications.cui},
                      {id: index+1, name: 'Escalão: ' + element.contract_lines[0].otherInfo},
                      {id: index+2, name: 'Fornecedor: ' + element.provider.designation}
                    ],
                  };
              break;
              case "3":
              case "4":
                tempChild = {
                    id: index,
                    name: element.contract_lines[0].drop.fullAddress.street1,
                    children: [
                      {id: index, name: 'Cód: ' + JSON.stringify(element.contract_lines[0].drop.identifications)},
                      {id: index+1, name: 'Escalão: ' + element.contract_lines[0].otherInfo},
                      {id: index+2, name: 'Fornecedor: ' + element.provider.designation}
                    ],
                  };
              break;
            }
            if (val.name == element.service.designation) {
              val.children.push(tempChild);
              index++;
            }
          });
        });
        return treeview;
      },
      processData(contract, rawData){
        let colorMap = new Map();
        this.locaisElectricity = [];
        this.locaisGPL = [];
        this.locaisGN = [];
        this.locaisSolar = [];
        contract.forEach( element => {
          let tempArray = rawData.filter(drop => drop.dropId == element.contract_lines[0].drop.id); // filters rawData by dropId
          //Consider sorting in the query itself
          tempArray.sort((a,b) => new Date(a.data.start) - new Date(b.data.start)); //sorts by date(oldest to newest)
          if (!colorMap.has(element.contract_lines[0].drop.id)) {colorMap.set(element.contract_lines[0].drop.id, this.getRandomColor())}
          switch (element.service.id) {
            case "1":
              let tempDataElectricity = [];
              tempArray.forEach( val => { //filters out all values from other services on the same drop
                if (val.data.service.id == "1") {
                  let tempVal = val.data.value * val.data.price;
                  tempDataElectricity.push(tempVal);
                };
              })
              //Incase of more than 1 value per month, must treat beforehand!!
              let tempElectricity = {
                id: element.id,
                drop: element.contract_lines[0].drop.id,
                name: element.contract_lines[0].drop.fullAddress.street1,
                color: colorMap.get(element.contract_lines[0].drop.id),
                cpe: element.contract_lines[0].drop.identifications.cpe,
                tipo: element.contract_lines[0].otherInfo,
                ciclo: "1", //TO FIX might be unecessary check document/image for electricity tipology
                data: tempDataElectricity,
              }
              this.isActive.electricity = true;
              this.locaisElectricity.push(tempElectricity);
              break;
            case "2":
              let tempDataGas = [];
              tempArray.forEach( val => {
                if (val.data.service.id == "2") {
                  let tempVal = val.data.value * val.data.price;
                  tempDataGas.push(tempVal);
                };
              });
              let tempGN = {
                id: element.id,
                drop: element.contract_lines[0].drop.id,
                name: element.contract_lines[0].drop.fullAddress.street1,
                color: colorMap.get(element.contract_lines[0].drop.id),
                escalao: element.contract_lines[0].otherInfo,
                localID: element.contract_lines[0].drop.identifications.cpe, //TO FIX, needs to be added to JSON @ strappi
                data: tempDataGas,
              }
              this.isActive.gn = true;
              this.locaisGN.push(tempGN);
              break;
            case "3":
              let tempDataGpl = [];
              tempArray.forEach( val => {
                if (val.data.service.id == "3") {
                  let tempVal = val.data.value * val.data.price;
                  tempDataGpl.push(tempVal);
                };
              });
              let tempGas = {
                id: element.id,
                drop: element.contract_lines[0].drop.id,
                name: element.contract_lines[0].drop.fullAddress.street1,
                color: colorMap.get(element.contract_lines[0].drop.id),
                cui: element.contract_lines[0].drop.identifications.cui,
                escalao: element.contract_lines[0].otherInfo,
                data: tempDataGpl
              }
              this.isActive.gpl = true;
              this.locaisGPL.push(tempGas);
              break;
            case "4":
              let tempDataSolar = [];
              tempArray.forEach( val => {
                let tempVal = val.data.value * val.data.price;
                tempDataSolar.push(tempVal);
              })
              let tempSolar = {
                id: element.id,
                drop: element.contract_lines[0].drop.id,
                name: element.contract_lines[0].drop.fullAddress.street1,
                color: colorMap.get(element.contract_lines[0].drop.id),
                escalao: element.contract_lines[0].otherInfo,
                localID: element.contract_lines[0].drop.identifications.cui, //TO FIX, same as above
                data: tempDataSolar,
              }
              this.isActive.solar = true;
              this.locaisSolar.push(tempSolar);
              break;
          }
        })
      },
      debounceSearch(target, text) {
        this.loaders.searchClient = true;
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.searchClients[target] = text;
        }, 500);
      },
      async pasteCoords() {
        let coords;
        try {
          coords = JSON.parse(await navigator.clipboard.readText());
        } catch(e) { return null; }
        if (!coords.lat || !coords.lng) return null;
        this.client.fullAddress.latitude = ""+coords.lat;
        this.client.fullAddress.longitude = ""+coords.lng;
        console.log("Coordenadas coladas");
      },
      showClientForm(client) {
        if (client) {
          this.clientChanged = false;
          this.client = JSON.parse(JSON.stringify(client));
          this.client.fullAddress.country = client.fullAddress.country ? client.fullAddress.country.id.toString() : null;
          this.client.fullAddress.district = client.fullAddress.district ? client.fullAddress.district.id.toString() : null;
          this.client.fullAddress.city = client.fullAddress.city ? client.fullAddress.city.id.toString() : null;
          this.client.fullAddress.area = client.fullAddress.area ? client.fullAddress.area.id.toString() : null;
          this.client.email1 = this.client.email1 ? this.client.email1 : "";
          this.client.email2 = this.client.email2 ? this.client.email2 : "";
        } else {
          this.clientChanged = true;
          this.client = {
            id: null,
            designation: null,
            taxNumber: null,
            phone1: null,
            phone2: null,
            email1: "",
            email2: "",
            webpage: null,
            fullAddress: {
              street1: null,
              street2: null,
              area: null,
              city: null,
              district: null,
              country: null,
              postalCode: null,
              latitude: null,
              longitude: null,
            },
            obs: null,
            activity: null,
          }
        }
        this.$store.dispatch("fetchAddresses").then((response) => {
          this.clientFormVisible = true;
        }).catch(err => {console.log(err)});
      },
      hideClientForm() {
        this.$refs.clientForm.reset();
        this.nifError = "";
        this.client.email1 = "";
        this.client.email2 = "";
        this.client.fullAddress.latitude = "";
        this.client.fullAddress.longitude = "";
        this.clientFormVisible = false;
      },
      submitClientForm() {
        if (this.$refs.clientForm.validate()) {
          let {id, ...client} = this.client;
          client.taxNumber = client.taxNumber ? client.taxNumber : null;
          //cant have "" (empty string) for REST API because it must be a number
          if (this.client.taxNumber != this.$store.getters.getClient(this.client.id).taxNumber &&
              this.$store.getters.taxNumberExists(this.client.taxNumber)) {
            this.nifError = "NIF já existe";
            //TODO should be done also on the API
          } else {
            $api.sendClient(id, client).then(response => {
              this.$store.commit('setClient', response.data);
              this.selectedClient = response.data.id;
              this.forceClientUpdate++;
            });
            this.hideClientForm();
          }
        }
      },
      deleteClient(client) {
        if (client) {
          this.client = JSON.parse(JSON.stringify(client));
          this.showDialogDeleteClient=true;
        }
      },
      showContactForm(contact) {
        if (contact) {
          this.contact = JSON.parse(JSON.stringify(contact));
          this.contact.client = this.contact.client.id;
          this.contact.email = this.contact.email ? this.contact.email : "";
        } else {
          this.contact = {
            id: null,
            name: null,
            position: null,
            phone1: null,
            phone2: null,
            email: "",
            obs: null,
            client: this.selectedClient
          }
        }
        this.contactFormVisible = true;
      },
      async showEventForm(event) {
        await this.$store.dispatch("fetchUsers");
        if (event) {
          this.event = JSON.parse(JSON.stringify(event));
          this.event.client = this.event.client.id;
          let datehour = this.dateToYYYYMMDDHHMM(this.event.datehour);
          this.event.service = this.event.service ? this.event.service.id : '0';
          this.event.date = datehour.substr(0, 10);
          this.event.hour = datehour.substr(11, 5);
          this.event.userName = this.event.user?.name;
          this.event.user = this.event.user?.id;
        } else {
          this.event = {
            id: null,
            date: null,
            datehour: null,
            hour: "09:00",
            user: this.me.id.toString(),
            userName: null,
            obs: null,
            client: this.selectedClient,
            scheduled: false,
            completed: false,
            service: '0',
          }
        }
        this.eventFormVisible = true;
      },
      hideContactForm() {
        this.$refs.contactForm.reset();
        this.contactFormVisible = false;
      },
      hideEventForm() {
        this.$refs.eventForm.reset();
        this.event.date = null;
        this.event.hour = null;
        this.eventFormVisible = false;
      },
      hideConsumptionForm() {
        this.$refs.consumptionForm.reset();
        this.consumptionFormVisible = false;
      },
      deleteContact(contact) {
        if (contact) {
          this.contact = JSON.parse(JSON.stringify(contact));
          this.showDialogDeleteContact=true;
        }
      },
      deleteEvent(event) {
        if (event) {
          this.event = JSON.parse(JSON.stringify(event));
          this.showDialogDeleteEvent=true;
        }
      },
      markEventComplete(event) {
        if (event) {
          $api.sendEvent(event.id, {completed: !event.completed}).then(response => {
            this.$store.dispatch("fetchEvents", {clientId: event.client.id, forceUpdate: true}).then((response) => {
              this.forceClientUpdate++; //force cached computed to refresh
              console.log("events updated");
            }).catch(err => {console.log(err)});
          });
        }
      },
      handleReponseDeleteContact: function(response) {
        if (response) {
          $api.deleteContact(this.contact.id).then(response => {
            this.$store.dispatch("fetchContacts", {clientId: this.contact.client.id, forceUpdate: true}).then((response) => {
              console.log("contact deleted");
            }).catch(err => {console.log(err)});
          }).catch(err => {console.log(err)});
        }
      },
      handleReponseDeleteClient: function(response) {
        if (response) {
          $api.deleteClient(this.client.id).then(response => {
            this.selectedClient = 0;
            this.$store.commit('removeClient', this.client);
            this.forceClientUpdate++; //force cached computed to refresh
            console.log("client deleted");
          }).catch(err => {console.log(err)});
        }
      },
      handleReponseDeleteEvent: function(response) {
        if (response) {
          $api.deleteEvent(this.event.id).then(response => {
            this.$store.dispatch("fetchEvents", {clientId: this.event.client.id, forceUpdate: true}).then((response) => {
              this.forceClientUpdate++; //force cached computed to refresh
              console.log("event deleted");
            }).catch(err => {console.log(err)});
          }).catch(err => {console.log(err)});
        }
      },
      submitContactForm() {
        if (this.$refs.contactForm.validate()) {
          let {id, ...contact} = this.contact;
          $api.sendContact(id, contact).then(response => {
            this.$store.dispatch("fetchContacts", {clientId: contact.client, forceUpdate: true}).then((response) => {
              console.log("contacts updated");
              this.hideContactForm();
            }).catch(err => {console.log(err)});
          });
        }
      },
      submitEventForm() {
        if (this.$refs.eventForm.validate()) {
          let {id, ...event} = this.event;

          try {
            event.datehour = new Date(event.date+" "+event.hour).toISOString();
          }catch(err) {
            /*defaults to system date*/
            event.datehour = new Date().toISOString();
          }

          $api.sendEvent(id, event).then(response => {
            this.$store.dispatch("fetchEvents", {clientId: event.client, forceUpdate: true}).then((response) => {
              console.log("events updated");
              this.hideEventForm();
            }).catch(err => {console.log(err)});
          });
        }
      },
      submitConsumption(service) {
        switch (service) { // This is ugly and breaks if more tabs are added, should be referencing tabs by name!
          case 3: { this.consumption.service = 1; break; }
          case 4: { this.consumption.service = 3; break; }
          case 5: { this.consumption.service = 2; break; }
          case 6: { this.consumption.service = 4; break; }
        }
        if (this.$refs.consumptionForm.validate()) {
          let {id, ...consumption} = this.consumption;
          $api.sendConsumption(id, consumption).then(resp => {
            this.$store.dispatch("fetchContracts", this.selectedClient).then((response) => {
              let drops = response.reduce(function (newArray, drop) {
                newArray.push(drop.id);
                return newArray;
              }, []);
              let today = new Date();
              let endDate = new Date(today.setMonth(today.getMonth()+1,1));
              endDate = this.dateToYYYYMMDD(endDate) + "T00:00:00.000Z";
              let startDate = new Date(today.setMonth(today.getMonth()-12,1));
              startDate = this.dateToYYYYMMDD(startDate) + "T00:00:00.000Z";
              this.$store.dispatch("fetchConsumption", {dropIds: drops, startDate: startDate, endDate: endDate, force: true}).then((res) => {
                this.processData([...response], res);
                console.log("Consumptions updated");
                this.hideConsumptionForm();
              }).catch(err => {console.log(err)});
            }).catch(err => {console.log(err)});
          });
        }
      },
      submitCountry() {
        this.loaders.submitCountry = true;
        $api.sendCountry(null, {designation: this.searchSync.country}).then(resp => {
          this.$store.dispatch("fetchAddresses", {forceUpdate: true}).then((response) => {
            this.loaders.submitCountry = false;
        }).catch(err => {console.log(err)});
        });
      },
      submitDistrict() {
        this.loaders.submitDistrict = true;
        $api.sendDistrict(null, {designation: this.searchSync.district, country: this.client.fullAddress.country}).then(resp => {
          this.$store.dispatch("fetchAddresses", {forceUpdate: true}).then((response) => {
            this.loaders.submitDistrict = false;
        }).catch(err => {console.log(err)});
        });
      },
      submitCity() {
        this.loaders.submitCity = true;
        $api.sendCity(null, {designation: this.searchSync.city, district: this.client.fullAddress.district}).then(resp => {
          this.$store.dispatch("fetchAddresses", {forceUpdate: true}).then((response) => {
            this.loaders.submitCity = false;
        }).catch(err => {console.log(err)});
        });
      },
      submitArea() {
        this.loaders.submitArea = true;
        $api.sendArea(null, {designation: this.searchSync.area, city: this.client.fullAddress.city}).then(resp => {
          this.$store.dispatch("fetchAddresses", {forceUpdate: true}).then((response) => {
            this.loaders.submitArea = false;
        }).catch(err => {console.log(err)});
        });
      },
      exportCSV(content, filename) {
        content = "\uFEFF" + content; //BOM char

        var blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      printSearch() {
        let filename = this.dateToYYYYMMDDHHMM(new Date());
        var processRow = function (row) {
          var finalVal = '';
          finalVal += (row.taxNumber ? row.taxNumber : '') + ';';
          finalVal += (row.designation ? row.designation : '') + ';';
          finalVal += (row.tradeName ? row.tradeName : '') + ';';
          finalVal += (row.fullAddress.district ? row.fullAddress.district.designation : '') + ';';
          finalVal += (row.fullAddress.city ? row.fullAddress.city.designation : '') + ';';
          finalVal += (row.fullAddress.area ? row.fullAddress.area.designation : '') + ';';
          finalVal += (row.fullAddress.street1 ? row.fullAddress.street1 : '') + ';';
          finalVal += (row.fullAddress.postalCode ? row.fullAddress.postalCode : '') + ';';
          finalVal += (row.activity ? row.activity : '') + ';';
          finalVal += (row.obs ? row.obs.replaceAll('\n', '') : '');
          return finalVal + '\n';
        }

        var csvFile = "";
        csvFile += "NIF;Designação;Designação Comercial;Distrito;Concelho;Freguesia;Rua;Código Postal;Actividade;Obs\n"
        this.clients.forEach(client => {
          csvFile += processRow(client);
        });

        this.exportCSV(csvFile, filename);
      },
      printEvents(client) {
        if (!client || !client.events) return null;

        var eventsDocDef = {
          pageSize: 'A4',
          pageOrientation: 'landscape',
          pageMargins: [ 10, 10, 10, 10 ],
          defaultStyle: {
            fontSize: 8
          },
          content: [
            { text: 'OCORRÊNCIAS', bold: true },
            ' ',
            { text: ['NIF: ', {text: this.clientData.taxNumber, bold: true }]},
            { text: ['Designação: ', { text: this.clientData.designation, bold: true }]},
            ' ',
            {
              table: {
                headerRows: 1,
                widths: [ 70, 100, 'auto', 70, 70 ],
                body: [
                  [ { text: 'Data/Hora', alignment: 'center' },
                    { text: 'Assunto', alignment: 'center' },
                    { text: 'Obs', alignment: 'center' },
                    { text: 'Criado', alignment: 'center' },
                    { text: 'Atribuido', alignment: 'center' }
                  ],
                  ...this.clientData.events.map(event => [
                    { text: (new Date(event.datehour) < new Date(event.created_at)) ? this.dateToYYYYMMDDHHMM(event.datehour) : this.dateToYYYYMMDDHHMM(event.created_at), alignment: 'center' },
                    { text: event.subject ? event.subject : '', alignment: 'left' },
                    { text: event.obs ? event.obs.replace('\n', '') : '', alignment: 'left' },
                    { text: event.createdBy ? event.createdBy.name : '', alignment: 'center' },
                    { text: event.user ? event.user.name : '', alignment: 'center' }
                  ])
                ]
              }
            }
          ]
        };
        pdfMake.createPdf(eventsDocDef).download('ocorrencias_'+this.clientData.id+'_'+this.dateToYYYYMMDDHHMM(new Date()));

        // let filename = "ocorrencias_"+client.id+"_"+this.dateToYYYYMMDD(new Date());

        // var processRow = (row) => {
        //   var finalVal = '';

        //   let date_hour = this.dateToYYYYMMDD(new Date());
        //   if (new Date(row.datehour) < new Date(row.created_at)) {
        //     date_hour = this.dateToYYYYMMDDHHMM(row.datehour);
        //   } else {
        //     date_hour = this.dateToYYYYMMDDHHMM(row.created_at);
        //   }

        //   finalVal += date_hour + ';';
        //   finalVal += (row.subject ? row.subject : '') + ';';
        //   finalVal += (row.obs ? row.obs.replace('\n', '') : '');
        //   finalVal += (row.createdBy ? row.createdBy.name : '') + ';';
        //   finalVal += (row.user ? row.user.name : '') + ';';
        //   return finalVal + '\n';
        // }

        // var csvFile = "";
        // csvFile += "NIF:"+client.taxNumber+"\n";
        // csvFile += "Designação:"+client.designation+"\n\n";
        // csvFile += "Data/Hora;Assunto;Obs;Criado;Atribuido\n"
        // for (let i = client.events.length; i > 0; i--) {
        //   csvFile += processRow(client.events[i-1]);
        // };

        //this.exportCSV(csvFile, filename);
      }
    },
    watch: {
      event: {
        deep: true,
        handler(event) {
          if (!event.id && new Date(event.date+" "+event.hour).getTime() > new Date().getTime()) {
            event.scheduled = true;
          }
        }
      },
      selectedClient: {
        deep: true,
        async handler(val) {
          this.isActive.electricity = false;
          this.isActive.gpl = false;
          this.isActive.gn = false;
          this.isActive.solar = false;
          this.isActive.consulting = false;
          if (val) {
            this.$router.push({path:'/'+val});
            this.$store.dispatch("fetchContracts", val).then((response) => {
              let drops = response.reduce(function (newArray, drop) {
                newArray.push(drop.id);
                return newArray;
              }, []);
              this.treeviewItems = this.buildTreeView(response);
              let today = new Date();
              let endDate = new Date(today.setMonth(today.getMonth()+1,1));
              endDate = this.dateToYYYYMMDD(endDate) + "T00:00:00.000Z";
              let startDate = new Date(today.setMonth(today.getMonth()-12,1));
              startDate = this.dateToYYYYMMDD(startDate) + "T00:00:00.000Z";
              this.$store.dispatch("fetchConsumption", {dropIds: drops, startDate: startDate, endDate: endDate}).then((res) => {
                this.processData([...response], res);
              }).catch(err => {console.log(err)});
            }).catch(err => {console.log(err)});

            let promises = [];
            this.loaders.clientInfo = true;
            /****** TO GET INFO FOR CLIENT *****/
            promises.push(this.$store.dispatch("fetchInfo", val).then((response) => {
              console.log("info done");
            }).catch(err => {console.log(err)}));
            /***********************/
            /****** TO GET CONTRACTS FOR CLIENT *****/
            promises.push(this.$store.dispatch("fetchContracts", val).then((response) => {
              console.log("contracts done");
            }).catch(err => {console.log(err)}));
            /***********************/
            /****** TO GET CONTACTS FOR CLIENT *****/
            promises.push(this.$store.dispatch("fetchContacts", {clientId: val}).then((response) => {
              console.log("contacts done");
            }).catch(err => {console.log(err)}));
            /***********************/
            /****** TO GET EVENTS FOR CLIENT *****/
            promises.push(this.$store.dispatch("fetchEvents", {clientId: val}).then((response) => {
              console.log("events done");
            }).catch(err => {console.log(err)}));
            /***********************/
            await Promise.all(promises);
            this.loaders.clientInfo = false;
            this.forceClientUpdate++;
          } else {
            this.$router.push({path:'/'});
          }
        }
      },
      searchClients: {
        deep: true,
        async handler(search) {
          await this.$store.dispatch("fetchClients", search).then((response) => {
            this.loaders.searchClient = false;
          }).catch(err => {this.loaders.searchClient = false;});
        }
      },
    },
    async created() {
      await this.$store.dispatch("fetchClients");
      await this.$store.dispatch("fetchAddresses");
      if (this.$route.params.id && this.$store.getters.getClient(this.$route.params.id)) {
        this.selectedClient = this.$route.params.id;
        let ref = "c"+this.$route.params.id;
        this.$refs[ref][0]?.scrollIntoView();
      }
    },
    mounted() {

    }
  }
</script>

<style scoped>
  input:required:invalid {
    border-color: #FF0000;
    box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075), 0 0 8px rgba(225, 0, 0, 0.6);
  }
  h5 {
    line-height: 1rem;
  }
</style>