import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "185e39",
        primaryLight: "c5dcb1",
        secondary: "949d5c",
        accent: colors.teal.base,
      },
    },
  },
});
