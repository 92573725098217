<template> 
  <v-col cols="12">
    <v-row>
      <v-col cols="9">
        <v-card>
          <BarChart
            :chartBarDatasets="graphSolarBarDatasets">
          </BarChart>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <PieChart
            :chartDatasets="graphSolarDatasets"
            :chartLabels="graphSolarLabels">
          </PieChart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cos="12">
        <v-data-table
          v-model="selectedSolar"
          :headers="headersSolar"
          :items="locaisSolar"
          :single-select="false"
          show-expand
          item-key="name"
          class="elevation-1"
          show-select>
          <template v-slot:expanded-item="{headers, item}">
            <td
            :colspan="headers.length"
            class="text-center justify-center">
              Informações adicionais acerca de {{item.name}}
            </td>
          </template>
          <template v-slot:[`item.data`]="{ item }">
              <v-chip color="transparent">
                {{item.data.reduce( (a,b) => a + b, 0)}}
              </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import helperComponents from "../plugins/helperComponents";

export default {
  name: "SolarTab",
  props: ['locaisSolar'],
  mixins: [helperComponents],
  components: {
      PieChart,
      BarChart,
    },
  data: () => ({
    selectedSolar: [],
    graphSolarLabels: [],
    graphSolarBarDatasets: [],
    graphSolarDatasets: [],
    headersSolar: [
        {
          text: 'Local de Entrega',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        { text: 'ID Local', value: 'localID'},
        { text: 'Escalão', value: 'escalao'},
        { text: 'Consumo', value: 'data[11]'},
        { text: 'Consumo Total', value: "data"}
      ],
  }),
  watch: {
    locaisSolar: function() {
      this.selectedSolar = [...this.locaisSolar];
    },
    selectedSolar: function(val) {
      this.graphSolarDatasets = this.buildDatasets(val);
      this.graphSolarLabels = this.buildLabels(val);
      this.graphSolarBarDatasets = this.buildBarDatasets(val);
    }
  },
  mounted() {
    this.selectedSolar = [...this.locaisSolar];
  }
}
</script>
