<template>
  <v-dialog
    v-model="show"
    max-width="25rem">
    <v-card class="text-center">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Iniciar sessão</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form ref="formLogin">
          <v-text-field
            label="Utilizador"
            v-model="username"
            name="username"
            type="text">
          </v-text-field>
          <v-text-field
            label="Senha"
            v-model="password"
            name="password"
            type="password">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="login()">Entrar</v-btn>
      </v-card-actions>
      <v-alert type="error" v-if="error">{{ error }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* eslint-disable */
  name: 'dialogLogin',
  props: ['showDialog', 'title'],
  data: () => ({
    response: null,
    username: "",
    password: "",
    error: "",
  }),
  methods: {
    login() {
      let user = this.username;
      let pass = this.password;
      this.$store.dispatch("AUTH_REQUEST", { user:user, pass:pass }).then(() => {
        //this.$emit("responseDialog", response.data.data.login.jwt);
        this.show = false;
        this.$router.push("/").catch(()=>{});
      });
    },
  },
  mounted() {

  },
  created() {

  },
  watch:{
    showDialog: function(val){
    //to set and clear form when dialog hidden from parent
      if (!val) {
        this.show = false;
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(val) {
        if (!val) {
          this.$emit("hideDialog");
          this.$refs.formLogin.reset();
        }
      }
    }
  },
}
</script>

<style scoped>
  .v-alert {
    margin: 0 !important;
  }
</style>