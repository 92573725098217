<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="9">
        <v-card>
          <BarChart
            :chartBarDatasets="graphGnBarDatasets">
          </BarChart>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <PieChart
            :chartDatasets="graphGnDatasets"
            :chartLabels="graphGnLabels">
          </PieChart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cos="12">
        <v-data-table
          v-model="selectedGn"
          :headers="headersGn"
          :items="locaisGn"
          :single-select="false"
          show-expand
          item-key="name"
          class="elevation-1"
          show-select>
          <template v-slot:expanded-item="{headers, item}">
            <td
            :colspan="headers.length"
            class="text-center justify-center">
              Informações adicionais acerca de {{item.name}}
            </td>
          </template>
          <template v-slot:[`item.data`]="{ item }">
              <v-chip color="transparent">
                {{item.data.reduce( (a,b) => a + b, 0)}}
              </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import helperComponents from "../plugins/helperComponents";

export default {
  name: "GnTab",
  props: ['locaisGn'],
  mixins: [helperComponents],
  components: {
      PieChart,
      BarChart,
    },
  data: () => ({
    selectedGn: [],
    graphGnLabels: [],
    graphGnBarDatasets: [],
    graphGnDatasets: [],
    headersGn: [
        {
          text: 'Local de Entrega',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        { text: 'CUI', value: 'localID'},
        { text: 'Escalão', value: 'escalao'},
        { text: 'Consumo', value: 'data[11]'},
        { text: 'Consumo Total', value: "data"}
      ],
  }),
  watch: {
    locaisGn: function() {
      this.selectedGn = [...this.locaisGn];
    },
    selectedGn: function(val) {
      this.graphGnDatasets = this.buildDatasets(val);
      this.graphGnLabels = this.buildLabels(val);
      this.graphGnBarDatasets = this.buildBarDatasets(val);
    }
  },
  mounted() {
    this.selectedGn = [...this.locaisGn];
  }
}
</script>
