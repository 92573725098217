<template>
  <v-container fluid>
    Settings
  </v-container>
</template>

<script>
  /* eslint-disable */
  import helper from "../plugins/helper";

  export default {
    name: "Settings",
    mixins: [helper],
    components: {

    },
    data: () => ({

    }),
    methods: {

    },
    watch: {

    },
    mounted() {

    }
  }
</script>

<style scoped>

</style>