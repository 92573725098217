/* eslint-disable */
import axios from "./plugins/axios";

export default {
  makeLogin(credentials) {
    return axios.post('graphql', {
      'query': ' \
        mutation { \
          login( \
            input: { \
              identifier: "' + credentials.user + '", \
              password: "' + credentials.pass + '" \
            } \
          ) { \
            jwt, \
            user { \
              id, \
              username \
            } \
          } \
        }'
    });
  },
  validateJWT() {
    return axios.get('users/me');
  },
  getClients() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          clients (sort:"designation:asc", limit:100000){ \
            id, \
            designation, \
            taxNumber, \
            tradeName, \
            obs, \
            activity, \
            fullAddress { \
              street1, \
              street2, \
              postalCode, \
              country {id, designation}, \
              district {id, designation}, \
              city {id, designation}, \
              area {id, designation} \
            } \
          } \
        }'
    });
  },
  getClientsInView(coords) {
    return axios.post('clients/inView', {
      'coords': JSON.stringify(coords)
    });
  },
  getInfo(id) {
    return axios.post('graphql', {
      'query': ' \
        query { \
          clients (where:{id:'+id+'}, limit:100000){ \
            taxNumber, \
            fullAddress{ \
              street1, \
              street2, \
              postalCode, \
              latitude, \
              longitude, \
              country{id,designation}, \
              district{id,designation}, \
              city{id,designation}, \
              area{id,designation}, \
            }, \
            webpage, \
            phone1, \
            phone2, \
            email1, \
            email2 \
          } \
        }'
    });
  },
  getContracts(id) {
    return axios.post('graphql', {
      'query': ' \
        query { \
          contracts (where:{client:'+id+'}) { \
            id, start, end, \
            provider {id, designation}, \
            contractNumber, \
            contract_lines { \
              id, otherInfo, \
              drop { id, identifications \
                fullAddress { \
                  street1, \
                  street2, \
                  postalCode, \
                  latitude, \
                  longitude, \
                  country{id,designation}, \
                  district{id,designation}, \
                  city{id,designation}, \
                  area{id,designation}, \
                } \
              } \
            }, service {id, designation} \
          } \
        }'
    });
  },
  getEventsByClient(id) {
    if (!id) return null;
    return axios.post('graphql', {
      'query': ' \
        query { \
          events (sort: "datehour:desc", where: {client:'+id+'}){ \
            id, subject, datehour, \
            created_at, \
            user {id, name, color}, \
            scheduled, completed, obs, \
            client {id}, \
            createdBy {id, name}, \
            service{id, designation}, \
          } \
        }'
    });
  },
  getEventsByUser(id) {
    if (!id) return null;
    return axios.post('graphql', {
      'query': ' \
        query { \
          events (sort: "datehour:asc", where: {user: '+id+', scheduled: true, completed: false}, limit: 10) { \
            id, \
            datehour, \
            scheduled, \
            completed, \
            subject, \
            obs, \
            client { \
              id, \
              designation \
            }, \
            createdBy { \
              id, \
              name \
            } \
          } \
        }'
    });
  },
  getEventsBetweenDates(startDate, endDate, scheduled=null) {
    try {
      let start = new Date(startDate).toISOString();
      let end = new Date(endDate).toISOString();
      let sched = scheduled == null? '' : 'scheduled:'+scheduled+', ';

      return axios.post('graphql', {
        'query': ' \
          query { \
            events (sort: "datehour:desc", where: {'+sched+'datehour_gte:"'+start+'", datehour_lte:"'+end+'"}){ \
              id, subject, datehour, \
              user {id, name, color}, \
              scheduled, completed, obs, \
              client {id, designation} \
            } \
          }'
      });
    } catch(err) {
      return err;
    }
  },
  getContacts(id) {
    return axios.post('graphql', {
      'query': ' \
        query { \
          contacts (where: {client:'+id+'}, sort:"name:asc") { \
            id, name, position, \
            phone1, phone2, email, obs, \
            client {id} \
          } \
        }'
    });
  },
  getProviders() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          providers { \
            id, \
            designation, \
          } \
        }'
    });
  },
  getUsers() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          users { \
            id, \
            name, \
            color, \
            blocked, \
          } \
        }'
    });
  },
  getConsumptions(ids, start, end) {
    let dates = "";
    if (start && end) {
      dates = ', start_gte:"'+start+'", end_lt:"'+end+'"';
    }
    return axios.post('graphql', {
      'query': ' \
        query { \
          consumptionsConnection (where: { drop_in: ['+ids.join()+'] '+dates+' }) { \
            groupBy { \
              drop { \
                connection { \
                  values { \
                    id, \
                    start, \
                    end, \
                    price, \
                    value, \
                    type, \
                    service{id}, \
                    drop {id} \
                  } \
                } \
              } \
            } \
          } \
        }'
    });
  },
  getCountries() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          countries (sort:"designation:asc", limit:100000){ \
            id, \
            designation, \
          } \
        }'
    });
  },
  getDistricts() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          districts (sort:"designation:asc", limit:100000){ \
            id, \
            designation, \
            country {id} \
          } \
        }'
    });
  },
  getCities() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          cities (sort:"designation:asc", limit:100000){ \
            id, \
            designation, \
            district {id} \
          } \
        }'
    });
  },
  getAreas() {
    return axios.post('graphql', {
      'query': ' \
        query { \
          areas (sort:"designation:asc", limit:100000){ \
            id, \
            designation, \
            city {id} \
          } \
        }'
    });
  },
  sendClient(id, data) {
    if (id) {
      return axios.put('clients/' + id, data);
    } else {
      return axios.post('clients', data);
    }
  },
  sendContact(id, data) {
    if (id) {
      return axios.put('contacts/' + id, data);
    } else {
      return axios.post('contacts', data);
    }
  },
  sendConsumption(id, data) {
    if (id) {
      return axios.put('consumptions/' + id, data);
    } else {
      return axios.post('consumptions', data);
    }
  },
  sendEvent(id, data) {
    if (id) {
      return axios.put('events/' + id, data);
    } else {
      return axios.post('events', data);
    }
  },
  sendCountry(id, data) {
    if (id) {
      return axios.put('countries/' + id, data);
    } else {
      return axios.post('countries', data);
    }
  },
  sendDistrict(id, data) {
    if (id) {
      return axios.put('districts/' + id, data);
    } else {
      return axios.post('districts', data);
    }
  },
  sendCity(id, data) {
    if (id) {
      return axios.put('cities/' + id, data);
    } else {
      return axios.post('cities', data);
    }
  },
  sendArea(id, data) {
    if (id) {
      return axios.put('areas/' + id, data);
    } else {
      return axios.post('areas', data);
    }
  },
  deleteContact(id) {
    return axios.delete('contacts/' + id);
  },
  deleteEvent(id) {
    return axios.delete('events/' + id);
  },
  deleteClient(id) {
    //TODO delete also events and contacts (and others) related
    return axios.delete('clients/' + id);
  }
};