<template>
  <v-container class="pa-0" fluid>
    <div style="height: calc(100vh - 64px);">
      <v-btn fab dark small absolute top right
        class="mx-2 mt-10"
        style="z-index: 9999"
        color="primary"
        @click.stop="nextMapLayer">
        <v-icon dark>
          {{mapIcon}}
        </v-icon>
      </v-btn>
      <l-map ref="map"
        style="height: 100%; width: 100%; z-index:5"
        :zoom="zoom"
        :center="center"
        :doubleClickZoom="false"
        @contextmenu.native="copyCoords"
        @update:bounds="boundsUpdated"
        @dblclick="copyCoords">
        <l-tile-layer :url="mapUrl" :attribution="mapAttribution"></l-tile-layer>
        <l-marker
          v-for="item in markers"
          :key="'m'+item.id"
          :lat-lng="[item.position.lat, item.position.lng]">
          <l-icon>
            <v-icon x-large
              :color="getColor(item.cliente.contrato.dataExpiracao)">
              mdi-map-marker
            </v-icon>
          </l-icon>
          <l-popup>
            <v-card flat
              min-width="280px"
              color="grey lighten-4">
              <v-toolbar dark
                :color="getColor(item.cliente.contrato.dataExpiracao)">
                <v-toolbar-title>{{item.cliente.nome}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="routeTo('/'+item.id)">
                  <v-icon>mdi-card-account-details-outline</v-icon>
                  <!-- ao clicar aqui ir para o perfil do cliente  -->
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span>Morada: {{item.cliente.morada}}</span>
                <v-spacer></v-spacer>
                <span>Contato: {{item.cliente.telemovel}}</span>
                <v-spacer></v-spacer>
                <span>Tipo de Contrato: {{item.cliente.contrato.tipo ?
                  item.cliente.contrato.tipo : "Não existe informação!"}}</span>
                <v-spacer></v-spacer>
                <span>Nº Contrato: {{item.cliente.contrato.nContrato ?
                  item.cliente.contrato.nContrato : "Não existe informação!"}}</span>
                <v-spacer></v-spacer>
                <span>Date de expiração: {{item.cliente.contrato.dataExpiracao ?
                  dateToYYYYMMDD(item.cliente.contrato.dataExpiracao) : "Não existe informação!"}}</span>
              </v-card-text>
            </v-card>
          </l-popup>
        </l-marker>
      </l-map>
    </div>

  </v-container>
</template>

<script>
  /* eslint-disable */
  import $api from '../api'
  import helper from "../plugins/helper";
  import {LMap, LTileLayer,LMarker,LCircleMarker,LPopup,LIcon} from 'vue2-leaflet';

  export default {
    name: "Map",
    mixins: [helper],
    components: {
     LMap,
     LTileLayer,
     LMarker,
     LCircleMarker,
     LPopup,
     LIcon,
    },

    data: () => ({
      selectedOpen: false,
      mapLayers: [
        {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          icon: 'mdi-road'
        },
        {
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution: '&copy; <a href="http://www.esri.com/">Esri</a>, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
          icon: 'mdi-satellite'
        }
      ],
      selectedMapLayer: 0,
      iconred: '../img/marker-icon-red.png',
      icon: '../assets/logo.png',
      zoom: 6.7397418081667943,
      center: [41.407295180483594, -8.524691203220513],
      markers: [],
      bounds: null,
      location: null,
      gettingLocation: false,
      errorStr: null
    }),
    computed: {
      clients() {
        return this.$store.getters.getClients();
      },
      mapUrl() {
        return this.mapLayers[this.selectedMapLayer].url;
      },
      mapAttribution() {
        return this.mapLayers[this.selectedMapLayer].attribution;
      },
      mapIcon() {
        return this.mapLayers[this.selectedMapLayer].icon;
      },
    },
    methods: {
      pushClientToMap(client) {
        this.markers.push({
          id: client.id,
          position: { lat: client.fullAddress.latitude, lng: client.fullAddress.longitude },
          visible: true,
          cliente: {
            nome: client.designation,
            morada: (client.fullAddress.street1?client.fullAddress.street1:'')+(client.fullAddress.street2?'<br>'+client.fullAddress.street2:''),
            telemovel: client.phone1,
            //TODO put dynamic data
            contrato:{
              tipo: "teste",
              nContrato: 123456789,
              dataExpiracao: new Date ("2021-08-17"),
            }
          }
        });
      },
      async clientsInView() {
        if (!this.bounds) {console.log("no bounds"); return null};
        let r = await $api.getClientsInView({ne: {...this.bounds._northEast}, sw: {...this.bounds._southWest}});
        if (r.data.resp) {
          let clientsInView = r.data.data;
          clientsInView.forEach(c => {
            let isOnMapIndex = this.markers.findIndex(m => m.id == c.id);
            let client = this.$store.getters.getClient(c.id);
            if (!client.fullAddress) {
              this.$store.dispatch("fetchInfo", c.id).then((response) => {
                this.pushClientToMap(response);
              });
            } else if (isOnMapIndex < 0){
              this.pushClientToMap(client);
            }
          });
        }
      },
      nextMapLayer() {
        this.selectedMapLayer++;
        if (this.selectedMapLayer >= this.mapLayers.length)
          this.selectedMapLayer = 0;
      },
      async copyCoords(e) {
        //TODO feedback / snackbar
        await navigator.clipboard.writeText(JSON.stringify(e.latlng));
        console.log("Coordenadas copiadas");
      },
      zoomUpdated (zoom) {
        console.log("zoomUpdated");
        this.zoom = zoom;
      },
      centerUpdated (center) {
        console.log("centerUpdated");
        this.center = center;
      },
      boundsUpdated (bounds) {
        this.bounds = bounds;
        this.clientsInView();
      },
      getColor(date){
        if (!date) return "green";
        let today = new Date();
        let diff = this.diffDatesMonths(today, date);
        if (diff < 3 ) {
          return "red";
        } else if (diff < 6) {
          return "orange";
        } else {
          return "green";
        }
      }
    },
    watch: {

    },
    async created() {
      await this.$store.dispatch("fetchClients");
    },
    async mounted() {
      this.$refs.map.mapObject.doubleClickZoom.disable();
      if (!("geolocation" in navigator)) {
        this.errorStr = "A Localização não está permitida";
        return;
      }
      navigator.geolocation.getCurrentPosition(
        location => {
          let latLng = L.latLng(location.coords.latitude, location.coords.longitude);
          this.$refs.map.mapObject.setView([latLng.lat, latLng.lng],21);
          this.gettingLocation = true;
        },
        err => {
          this.$refs.map.mapObject.setView(this.center,17);
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
      await this.clientsInView();
    }
  }
</script>

<style scoped>
</style>