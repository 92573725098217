<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props:['chartDatasets','chartLabels'],

   data: () => ({
    chartData: {
      labels: [],
      datasets: []
    },
    options: {
      title: {
        display: true,
        text: "Último Mês"
      },
        responsive: true,
        maintainAspectRatio: false,
    }
  }),

  methods: {
    makeChart() {
      this.chartData.datasets = [];
      this.chartData.labels = [...this.chartLabels];
      this.chartData.datasets.push({...this.chartDatasets});
      this.$data._chart.update();
    }
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
    this.makeChart();
  },
  watch: {
    chartDatasets: function () {
      this.makeChart();
      // console.log(this.chartData);
    }
  }
}
</script>
