import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "./plugins/axios";
import { EventBus } from './plugins/eventBus';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false

Vue.prototype.$http = axios;

axios.interceptors.request.use(
  (config) => {
    EventBus.$emit("isBusy", true);
    let token = store.getters.getToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }
    return config;
  },
  (error) => {
    EventBus.$emit("isBusy", false);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    EventBus.$emit("isBusy", false);
    return response;
  },
  (error) => {
    EventBus.$emit("isBusy", false);
    return Promise.reject(error);
  }
);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')