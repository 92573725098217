import Worker from 'worker-loader!./worker'
import * as Comlink from 'comlink'

const worker = Comlink.wrap(new Worker())

export const toUpperCase = worker.toUpperCase
export const doHardWork = worker.doHardWork

// import Worker from 'worker-loader!./worker'
// const worker = new Worker()

// worker.addEventListener('message', (e)=>{
//   console.log('we got back', e);
// })

// export function sendMessage(msg){
//     worker.postMessage(msg)
// }