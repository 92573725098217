import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Map from '../views/Map.vue'
import Settings from '../views/Settings.vue'
import store from "../store";
import Schedule from '../views/Schedule.vue'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  store.dispatch("AUTH_VALIDATE").then(response => {
    console.log(response);
    if (!response) {
      next();
      return;
    }
    next("/login");
  });
};

const ifAuthenticated = (to, from, next) => {
  store.dispatch("AUTH_VALIDATE").then(response => {
    if (response) {
      next();
      return;
    }
    next("/login");
  });
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const routes = [
  // {
  //   path: '/home/:typeTargets*',
  //   name: 'Home',
  //   component: Home,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/home/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/home/:id',
    name: 'HomeWithId',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/map',
    name: 'Mapa',
    component: Map,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/schedule',
    name: 'Agendamentos',
    component: Schedule,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/settings/:item',
    name: 'Definições',
    component: Settings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/:id',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  { path: '*', redirect: '/' },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  baseURL: process.env.VUE_APP_API_URL,
  routes
})

export default router