<template>
  <section class="pdf-content ma-5">
    <h4 class="time">NIF: <b>{{nif}}</b></h4>
    <h4 class="time">Designação: <b>{{designacao}}</b></h4>
    <br/>
    <table>
      <thead>
      <tr>
        <th style="width: 150px">Data/Hora</th>
        <th style="width: 150px">Assunto</th>
        <th>OBS</th>
        <th style="width: 150px">Criado</th>
        <th style="width: 150px">Atribuido</th>
      </tr>
      </thead>
      <tbody v-if="ocorrencias">
        <tr v-for="ocorrencia in ocorrencias" :key="ocorrencia.id">
          <td class="text-center">{{getEventDate(ocorrencia)}}</td>
          <td>{{ocorrencia.subject}}</td>
          <td>{{ocorrencia.obs.replace('\n', '')}}</td>
          <td class="text-center">{{ocorrencia.createdBy.name}}</td>
          <td class="text-center">{{ocorrencia.user.name}}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import helper from "../plugins/helper";

export default {
  name: "PdfContent",
  mixins: [helper],
  props: ['nif', 'designacao', 'ocorrencias'],
  data() {
    return {

    }
  },
  mounted() {
    this.$nextTick(() => {

    });
  },
  methods: {
    getEventDate(event) {
      let date_hour = this.dateToYYYYMMDD(new Date());
      if (new Date(event.datehour) < new Date(event.created_at)) {
        date_hour = this.dateToYYYYMMDDHHMM(event.datehour);
      } else {
        date_hour = this.dateToYYYYMMDDHHMM(event.created_at);
      }
      return date_hour;
    }
  }
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
.text-center {
  text-align: center;
}
</style>