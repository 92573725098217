<template> 
  <v-col cols="12">
    <v-row>
      <v-col cols="9">
        <v-card>
          <BarChart
            :chartBarDatasets="graphElectricityBarDatasets">
          </BarChart>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <PieChart
            :chartDatasets="graphElectricityDatasets"
            :chartLabels="graphElectricityLabels">
          </PieChart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cos="12">
        <v-data-table
          v-model="selectedElectricity"
          :headers="headersElectricity"
          :items="locaisElectricity"
          :single-select="false"
          show-expand
          item-key="name"
          class="elevation-1"
          show-select>
          <template v-slot:expanded-item="{headers, item}">
            <td
            :colspan="headers.length"
            class="text-center justify-center">
              Informações adicionais acerca de {{item.name}}
            </td>
          </template>
          <template v-slot:[`item.data`]="{ item }">
              <v-chip color="transparent">
                {{item.data.reduce( (a,b) => a + b, 0)}}
              </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import helperComponents from "../plugins/helperComponents";

export default {
  name: "EletricityTab",
  props: ['locaisElectricity'],
  mixins: [helperComponents],
  components: {
      PieChart,
      BarChart,
    },
  data: () => ({
    selectedElectricity: [],
    graphElectricityDatasets: [],
    graphElectricityLabels: [],
    graphElectricityBarDatasets: [],
    headersElectricity: [
        {
          text: 'Local de Entrega',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        { text: 'CPE', value: 'cpe'},
        { text: 'Tipo', value: 'tipo'},
        { text: 'Consumo', value: 'data[11]'},
        { text: 'Consumo Total', value: "data"}
      ],
  }),
  watch: {
    locaisElectricity: function() {
      this.selectedElectricity = [...this.locaisElectricity];
    },
    selectedElectricity: function(val) {
      this.graphElectricityDatasets = this.buildDatasets(val);
      this.graphElectricityLabels = this.buildLabels(val);
      this.graphElectricityBarDatasets = this.buildBarDatasets(val);
    }
  },
  mounted() {
    this.selectedElectricity = [...this.locaisElectricity];
  }
}
</script>
