<template>
  <v-app id="inspire" class="flex">

    <!-- <v-progress-linear absolute style="z-index:99999 !important"
      :active="busy"
      :indeterminate="busy"
      color="white">
    </v-progress-linear> -->

    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-app-bar app color="secondary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="$store.getters.isAuthenticated"
        icon
        @click.stop="showDialogLogout=true">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <dialog-login
      :showDialog = "showDialogLogin"
      @hideDialog = "showDialogLogin=false"
      @responseDialog = "handleReponseLogin">
    </dialog-login>

    <dialog-confirmation
      :showDialog = "showDialogLogout"
      :question = "'Terminar Sessão?'"
      :message = "'Todos os dados locais serão eliminados'"
      @hideDialog = "showDialogLogout=false"
      @responseDialog = "handleReponseLogout">
    </dialog-confirmation>

    <v-navigation-drawer v-if="$store.getters.isAuthenticated"
      v-model="drawer"
      fixed
      temporary
      class="pt-14">
      <v-list nav dense>
        <v-list-item @click="routeTo('/')">
          <v-list-item-action>
            <v-icon color="primary">
              mdi-home
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-body-1">Inicio</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--  -->
        <v-list-item @click="routeTo('/map')">
          <v-list-item-action>
            <v-icon color="primary">
              mdi-map
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-body-1">Mapa</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="routeTo('/Schedule')">
          <v-list-item-action>
            <v-icon color="primary">
              mdi-calendar
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-body-1">Agendamentos</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          prepend-icon="mdi-cog-outline">
          <template v-slot:activator>
            <!--<v-list-item @click="routeTo('settings')">
              <v-list-item-action>
                <v-icon color="primary">
                  mdi-cog-outline
                </v-icon>
              </v-list-item-action>-->
              <v-list-item-content>
                <v-list-item-title>
                  <div class="text-body-1">Definições</div>
                </v-list-item-title>
              </v-list-item-content>
            <!--</v-list-item>-->
          </template>
          <v-list-item @click="routeTo('/settings/defX')">
            <v-list-item-action>
              <!-- to create left space -->
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <div class="text-body-1">Definição X</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <div class="text-center">
      <v-btn icon style="position:absolute;bottom:0;right:0" class="mb-2 mx-2" color="primary"
        @click.stop="forceRefreshClients">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view
        @showLogin="handleShowLogin"
        @isBusy="handleBusy"></router-view>
    </v-main>

  </v-app>
</template>
<script>
/* eslint-disable */
import { EventBus } from './plugins/eventBus';
import helper from "./plugins/helper";
import DialogConfirmation from "./components/DialogConfirmation";
import DialogLogin from "./components/DialogLogin";

export default {
  name: 'App',
  mixins: [helper],
  components: {
    DialogConfirmation,
    DialogLogin,
  },
  data: () => ({
    title: process.env.VUE_APP_TITLE+" Dashboard",
    drawer: null,
    group: null,
    showDialogLogin: false,
    showDialogLogout: false,
    busy: false,
  }),
  mounted: function() {
    EventBus.$on('isBusy', this.handleBusy);
  },
  methods: {
    async forceRefreshClients() {
      this.$store.commit("clearClientsLastUpdate");
      await this.$store.dispatch("fetchClients");
    },
    handleShowLogin: function() {
    //to handle login requests from other views
      if (navigator.onLine) {
        this.showDialogLogin = true;
      }
    },
    handleReponseLogin: function(token) {
      //not being used
      console.log(token);
      this.showDialogLogin = false;
    },
    handleReponseLogout: function(response) {
      if (response) {
        this.$store.dispatch("AUTH_LOGOUT").then(() => {
          this.$router.push("/login").catch(()=>{});
        });
      }
    },
    handleBusy: function(state) {
      this.busy = state;
    }
  }
};
</script>

<style scoped>
  .v-app-bar.v-app-bar--fixed {
    z-index: 201 !important;
  }
  .v-navigation-drawer--temporary {
    z-index: 200 !important;
  }
</style>

<style>
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 10px;  /* Remove scrollbar space */
    height: 10px;
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
}
.makeGreen {
  color: green;
}
.makeRed {
  color: red;
}
</style>